import PropTypes from "prop-types";
import React from "react";
import Caption from "./Caption";
import ActionButtonPopUp from "./ActionButtonPopUp";
import DataTable from "./MDataTable";
import DataTablePagenation from "./MDataTablePagenation";

function ListSection({
  captionTitle,
  tableMsg,
  btnGroups,
  columns,
  data,
  onSelectionChange,
  handleAction,
  disabledCheckbox,
  filterData = {},
  apiURL,
  tableRef,
  is_pagenation = false,
}) {
  return (
    <div className="py-6 sm:px-6">
      <div className="relative">
        <section className="px-4" aria-labelledby="contact-heading">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                  <Caption title={captionTitle} />
                  {btnGroups.length > 0 ? (
                    <ActionButtonPopUp btnGroups={btnGroups} csvData={data || []} handleAction={handleAction} />
                  ) : null}
                </div>
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                  <div className="lg:gap-y-0 lg:gap-x-8">
                    {(!is_pagenation) ? <DataTable
                      tableMsg={tableMsg || 'record'}
                      columns={columns}
                      data={data}
                      disabledCheckbox={disabledCheckbox || false}
                      onSelectionChange={onSelectionChange}
                    /> : <DataTablePagenation
                      tableMsg={tableMsg || 'record'}
                      columns={columns}
                      tableRef={tableRef}
                      filterData={filterData}
                      apiURL={apiURL}
                      disabledCheckbox={disabledCheckbox || false}
                      onSelectionChange={onSelectionChange}
                    />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

ListSection.propTypes = {
  // "btnGroups.length": PropTypes.number,
  captionTitle: PropTypes.string,
  btnGroups: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  onSelectionChange: PropTypes.func,
};

ListSection.defaultProps = {
  // "btnGroups.length": 0,
  captionTitle: "",
  btnGroups: [],
  columns: [],
  data: [],
  onSelectionChange: () => {
    // do nothing.
  },
};

export default ListSection;
