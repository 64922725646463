export default {
  driver: "Driver",
  addDriver: "AddDriver",
  editDriver: "EditDriver",
  driverProfile: "DriverProfile",
  ApproveDriver: "ApproveDriver",
  UpproveDriver: "UnapprovedDriver",
  EnrollmentDriver: "EnrollmentDriver",
  ActiveDriver: "ActiveDriver",
  InactiveDriver: "InactiveDriver",
  OnlineDriver: "OnlineDriver",
  OfflineDriver: "OfflineDriver",
};
