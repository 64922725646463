import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import DataTable from "../../../components/MDataTable";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

let Id = "";
// List Settings
function Summary() {
  // const [trips, setTrips] = useState([]);
  const columns = [
    {
      title: "ID", field: "trip_id", render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={`/tripsdetail/${rowData.trip_id}`}
        >
          {rowData.invoice_no}
        </Link>
      )
    },
    { title: "Driver", field: "driver_info" },
    {
      title: "Vehicle", field: "vehicle_maker", render: (rowData) => (
        <span>
          {rowData.vehicle_maker} {rowData.vehicle_model_code} [{rowData.vehicle_color}]
        </span>
      )
    },
    { title: "Book Date", field: "booking_date" },
    { title: "Sub Total", field: "subtotal" },
    { title: "Total", field: "total" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "completed"
              ? "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-green-100 text-green-800"
              : "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-red-100 text-red-800"
          }
        >
          {" "}
          {rowData.status}
        </span>
      ),
    },
  ];
  const [data, setData] = useState([]);
  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  }
  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'trip/list', params: { user_id: Id, user_type: 'customer' } }).then((data) => {
      if (data.status === 200) {
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Trips
            </h2>
            <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show customer trips.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <DataTable
              columns={columns}
              data={data}
              disabledCheckbox={true}
              onSelectionChange={onSelectionChange}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
