import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Price";
import AddEditCaption from "../../components/AddEditCaption";
import ButtonSection from "../../components/ButtonSection";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import Dropdown from "../../components/Form/Dropdown";
// For Add price
function Add({ history }) {
  const typeOpt = [
    // 
    { id: 1, title: "Driver Fee", value: "driver" },
    { id: 2, title: "Return Fee", value: "return" },
    { id: 3, title: "Waiting Fee", value: "waiting" },
  ];
  const [fromList, setFromList] = useState([]);
  const [toList, setToList] = useState([]);
  const [fields, setFields] = useState({});
  const maxValue = 60;
  const [errors, setErrors] = useState({
    title: "",
    code: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter title"
      flag = false
    }

    if (!fields.type) {
      error['type'] = "Please select type"
      flag = false
    }
    if (!fields.from_number) {
      error['from_number'] = "Please select from number"
      flag = false
    }
    if (!fields.to_number) {
      error['to_number'] = "Please select to number"
      flag = false
    }
    if (!fields.price) {
      error['price'] = "Please enter price"
      flag = false
    }
    // if (!fields.status) {
    //   error['status'] = "Please select status"
    //   flag = false
    // }
    // console.log(error)
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    MinMaxKm(0, maxValue, 'from_number');
  }, []);

  function MinMaxKm(minimumKm, maximumKm, type) {
    let no = []
    let minKm = minimumKm
    let maxKm = maximumKm
    let number = {}
    for (let i = minKm; i <= maxKm; i++) {
      number = {
        title: i,
        value: i
      }
      no.push(number)
    }
    if (type === "to_number") {
      setToList([...no, {
        title: "More",
        value: 1000
      }])
    } else {
      setFromList(no)
    }
  }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "price/create", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/price");
            // history.push({ pathname: "/price", state: { is_added: true } });
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };

  function handleChange(field, e) {
    if (field === "from_number") {
      MinMaxKm((Number(e.target.value) + 1), maxValue, "to_number");
    }
    setFields({ ...fields, [field]: e.target.value });
  }

  return (
    <>
      <Breadcrumb module={labels.addPrice} />

      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <AddEditCaption title={labels.addPrice} />
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">

                              <div className="">
                                <Label title='Title' />
                                <div className="mt-1">
                                  <TextInput id='title' name='title' value={fields.title} handleChange={handleChange} is_number={false} />
                                </div>
                                {errors.title ? <Label title={'title'} fieldError={errors.title} /> : null}
                              </div>
                              <div className="">
                                <Label title='Type' />
                                <div className="mt-1">
                                  <Dropdown id='type' name='type' value={fields.type} handleChange={handleChange} status={typeOpt} />
                                </div>
                                {errors.type ? <Label title={'type'} fieldError={errors.type} /> : null}
                              </div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                              <div className="">
                                <Label title='From' />
                                <div className="mt-1">
                                  <Dropdown id='from_number' name='from_number' value={fields.from_number} handleChange={handleChange} status={fromList} />
                                </div>
                                {errors.from_number ? <Label title={'from_number'} fieldError={errors.from_number} /> : null}
                              </div>
                              <div className="">
                                <Label title='To' />
                                <div className="mt-1">
                                  <Dropdown id='to_number' name='to_number' value={fields.to_number} handleChange={handleChange} status={toList} />
                                </div>
                                {errors.to_number ? <Label title={'to_number'} fieldError={errors.to_number} /> : null}
                              </div>
                              <div className="">
                                <Label title='Price' />
                                <div className="mt-1">
                                  <TextInput id='price' name='price' value={fields.price} handleChange={handleChange} is_number={true} />
                                </div>
                                {errors.price ? <Label title={'price'} fieldError={errors.price} /> : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ButtonSection path={'/price'} handleSubmit={handleSubmit} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Add;
