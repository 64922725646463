import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Currency";

// For Add currency
function Add({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];
  const fieldArr = [
    { id: 0, title: "Title", name: "name", type: "TextInput", placeholder: "Enter your title" },
    { id: 0, title: "Code", name: "code", type: "TextInput", placeholder: "Enter your code" },
    { id: 2, title: "Symbol", name: "symbol", type: "TextInput", placeholder: "Enter your symbol" },

    {
      id: 1,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    code: "",
    symbol: "",
    status: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please enter currency title"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please enter currency code"
      flag = false
    }
    if (!fields.symbol) {
      error['symbol'] = "Please enter currency symbol"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "currency/create", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/currency");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }
  return (
    <>
      <Breadcrumb module={labels.addCurrency} />
      <AddEditSection
        title="Add Currency"
        path="/currency"
        fields={fieldArr}
        fieldError={errors}

        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
