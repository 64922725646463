import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTable, {MTableToolbar} from "material-table";
import tableIcons from './miui-icons'
import configuration from '../config';
import { toast } from 'react-toastify';
import DatePicker from "./Form/DatePicker";
import Label from "./Form/Label";

// Table
function DataTable({ columns, tableRef, onSelectionChange, tableMsg, disabledCheckbox, apiURL, fieldArr, handleChangeFilter, fieldsFilter, errorsFilter, filterData}) {
  const [selectedRow] = useState(null);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [pageSize, SetPageSize] = useState(10);
  // const tableRef = React.createRef();
  // let defaultLimit = 50;
  let pageSizeOptions = []
  function addPush(number) {
    if (totalRecords > number) {
      pageSizeOptions.push(number)
    }
  }
  addPush(10)
  addPush(50)
  addPush(100)
  addPush(200)
  addPush(500)
  // addPush(1000)
  // pageSizeOptions.push(totalRecords)
  const FilterByDateRange = () => {   /* function for adding 2 textfields for date range */
    return (
      <>
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-3 mt-5">
          {fieldArr.map((field, index) => {
            return (
              <div className="" key={index}>
                <Label title={field.title} />
                <div className="mt-1 relative">
                  {field.type === "DatePicker" ? (
                    <DatePicker
                      id={field.name}
                      name={field.name}
                      handleChange={(name, e) => handleChangeFilter(name, e)}
                      value={fieldsFilter[field.name]}
                    />
                  ) : null}
                </div>
                {errorsFilter[field.name] ? <Label title={field.title} fieldError={errorsFilter[field.name]} /> : null}
              </div>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      tableRef={tableRef}
      data={query =>
        new Promise((resolve, reject) => {
          // console.log(query)
          SetPageSize(query.pageSize)
          configuration.getAPI({url:apiURL, params:{
            filterData:JSON.stringify(filterData),
            sizePerPage:query.pageSize, 
            page:query.page,
            search:query.search,
            sort_by:(query.orderBy)?query.orderBy.field:'',
            sort_direction:(query.orderDirection)?query.orderDirection:''
          }}).then((data) => {
            if(data.status === 200){
              SetTotalRecords(data.payload.recordCount)
              resolve({
                data: data.payload.record,
                page: data.payload.page,
                totalCount: data.payload.recordCount,
              })
              // pageSizeOptions = []
            }
          }).catch(error => {
            return toast.error(error.message)
          });
        })
      }
      title={false}
      options={{
        emptyRowsWhenPaging: false,
        search: true,
        selection: (disabledCheckbox) ? false : true,
        sorting: true,
        // filtering: true,
        pageSize: pageSize,
        exportButton: {
          csv: true,
        },
        pageSizeOptions:pageSizeOptions,
        headerStyle: { whiteSpace: "nowrap" },
        rowStyle: (rowData) => ({
          whiteSpace: "nowrap",
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
      }}
      localization={{
        toolbar: {
          exportCSVName: "Export as CSV",
        }
      }}
      // components={{
      //   Toolbar: (props) => (
      //     <>
      //       <MTableToolbar {...props} />
      //       <FilterByDateRange />  
      //     </>
      //   ),
      // }}
      onSelectionChange={onSelectionChange}
    />
  );
}

export default DataTable;
