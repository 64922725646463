import React, { useEffect, useState } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/HelpCategory";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Edit Help Category
let Id = "";
function Edit({ history }) {
  const [fields, setFields] = useState({});
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({});

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];

  const userTypesOpt = [
    { id: 1, title: "Customer", value: "customer" },
    { id: 2, title: "Driver", value: "driver" }
  ];

  const [fieldArr, setFieldArr] = useState([])
  const columnArr = [
    {
      id: 0,
      title: "User Type",
      name: "user_type",
      type: "Dropdown",
      options: userTypesOpt,
    },
    // { id: 1, title: "Order no", name: "order_no", type: "TextInput" },
    { id: 2, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'help_categories', is_update: 1 } }).then((data) => {
      if (data.status === 200) {
        let orderNoArr = []
        UND.each(data.payload, (singleRec) => {
          orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
        })
        const orderNoObj = {
          id: 1,
          title: "Order No",
          name: "order_no",
          type: "Dropdown",
          options: orderNoArr,
        }
        columnArr.push(orderNoObj)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'help-category/list', params: { help_category_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    getlanguages();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getlanguages() {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let titleArr = []
        UND.each(data.payload, (lang, ind) => {
          // titleArr.push({ id: ind, title: "Title", name: "title_" + lang.code, type: "TextInput" })
          titleArr.push({ id: ind, title: "Title[" + lang.title + "]", name: "title_" + lang.code, type: "TextInput", placeholder: `Enter your title in ${lang.title}` })
        })
        const fullCoulnmArr = columnArr.concat(titleArr);
        setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.user_type) {
      error['user_type'] = "Please select your user type"
      flag = false
    }
    if (!fields.order_no) {
      error['order_no'] = "Please enter order no"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }
    UND.each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter your title in " + lang.title
        flag = false
      }
    })
    setErrors({ ...error })
    return flag;
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      configuration.postAPI({ url: 'help-category/update', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/help-category')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.editHelpCategory} />
      <AddEditSection
        title="Edit Help Category"
        path="/help-category"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
