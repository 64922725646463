import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/ContactUs";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    { title: "ID", field: "contact_us_id"},
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Message", field: "message" },
    { title: "Date", field: "created_at" },
  ];

  const btnGroups = [];
  const [data, setData] = useState([]);
  
  useEffect(() => {
    configuration.getAPI({url:'common/list-contact-us', params:{}}).then((data) => {
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.contactUs} />
      <ListSection
        captionTitle="View Contact Us"
        columns={columns}
        data={data}
        disabledCheckbox={true}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
