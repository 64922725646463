import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import React from "react";
import labels from "../../constants/Setting";

// List Setting Menu
function Menu() {
  const cards = [
    {
      id: 0,
      module: "General",
      icon: "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4",
      menu: [
        { id: 0, title: "Settings", redirect: "/personal-settings" }
      ],
    },
    {
      id: 1,
      module: "Users & Control",
      icon: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z",
      menu: [
        { id: 0, title: "Admin User", redirect: "/admin-user" },
        { id: 1, title: "Login History", redirect: "/log" },
        { id: 2, title: "Role", redirect: "/role" },
            ],
    },
    {
      id: 2,
      module: "Content",
      icon: "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z",
      menu: [
        { id: 0, title: "Static Pages", redirect: "/cms" },
        { id: 1, title: "Language Labels", redirect: "/label" },
        { id: 2, title: "Help Categories", redirect: "/help-category" },
        { id: 4, title: "Help", redirect: "/help" },
        { id: 5, title: "Cancellation Reason", redirect: "/cancellation-reason" },
        { id: 5, title: "Rating Criterias", redirect: "/rating-criterias" },
        { id: 6, title: "Promotion Code", redirect: "/promotion-code" },
        { id: 7, title: "Brand", redirect: "/brand" },
        { id: 7, title: "Price", redirect: "/price" },
        { id: 2, title: "Contact Us", redirect: "/contact-us" },
        { id: 2, title: "Additional Charge", redirect: "/additional-charge" },
        { id: 8, title: "Notification", redirect: "/notification-log" },
      ],
    },
    {
      id: 4,
      module: "Localizations",
      icon: "M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129",
      menu: [
        { id: 0, title: "Language", redirect: "/language" },
        { id: 1, title: "Currency", redirect: "/currency" },
        { id: 2, title: "Tax", redirect: "/tax" },
        { id: 3, title: "Country", redirect: "/country" },
        { id: 4, title: "State", redirect: "/state" },
        { id: 5, title: "City", redirect: "/city" },
        { id: 6, title: "Zip Code", redirect: "/zipcode" },
      ],
    },
    {
      id: 5,
      module: "Customizations",
      icon: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
      icon2: "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
      menu: [
        { id: 0, title: "Email Templates", redirect: "/email-template" },
        { id: 1, title: "Push Templates", redirect: "/push-template" },
        { id: 2, title: "Sms Templates", redirect: "/sms-template" },
      ],
    },

    { module: "Data Administration", icon: "M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4", menu: [{ title: "Data Backup", redirect: "/data-backup" }] }
  ];

  return (
    <>
      <Breadcrumb module={labels.setting} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section
            className="mt-20 pb-32 px-4"
            aria-labelledby="contact-heading"
          >
            <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
              {cards.map((card) => {
                return (
                  <div
                    className="flex flex-col bg-white rounded-2xl shadow-xl mb-20 box_bg"
                    key={card.id}
                  >
                    <div className="flex-1 relative pt-16 px-6 pb-4 md:px-8">
                      <div className="absolute top-0 p-4 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-8 w-8 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d={card.icon}
                          />
                          {card.module === "Customizations" ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d={card.icon2}
                            />
                          ) : null}
                        </svg>
                      </div>
                      <h3 className="text-xl font-medium text-gray-900 text_theme">
                        {card.module}
                      </h3>
                    </div>
                    {card.menu.map((submenu, index) => {
                      let submenuRow =
                        index % 2 === 0
                          ? "p-4 bg-gray-50 md:px-8 setting_menu_bg"
                          : "p-4 bg-white md:px-8 box_bg";

                      submenuRow += card.menu.length - 1 === index ? " rounded-bl-2xl rounded-br-2xl" : ''
                      return (
                        <div className={submenuRow} key={submenu.id}>
                          {submenu.redirect !== "/switch" && submenu.redirect !== '/color_theme' ?
                            <Link
                              to={submenu.redirect}
                              className="text-base font-medium text-indigo-700 hover:text-gray-900 setting_menu_link"
                            >
                              {submenu.title}
                              <span aria-hidden="true"> &rarr;</span>
                            </Link> : ""}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Menu;
