import { createDecipheriv } from 'crypto';
const baseURL = process.env.REACT_APP_BASE_URL
const apiBaseURL = process.env.REACT_APP_API_BASE_URL
const algorithm = 'aes-256-cbc';

const config = {
  appName: process.env.REACT_APP_NAME,
  tinyAPIKey: process.env.REACT_APP_TINY_API_KEY,
  baseURL,
  apiBaseURL,

  decrypt(Securitykey, iv, data) {
    return new Promise((resolve) => {
      try {
        const decipher = createDecipheriv(algorithm, Securitykey, iv);
        let decryptedData = decipher.update(data, "hex", "utf-8");
        decryptedData += decipher.final("utf8");
        resolve(decryptedData)
        return;
      }
      catch (err) {
        // console.log(err)
      }
    })
  },

  getAPI(data) {
    return new Promise((resolve, reject) => {
      let url = new URL(`${baseURL}${data.url}`)
      // url.search = new URLSearchParams({...data.params, code:'EN'}).toString();
      url.search = new URLSearchParams({ ...data.params, lang_code: 'EN' }).toString();
      fetch(url).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
  
  postAPI(data) {
    return new Promise((resolve, reject) => {
      fetch(
        `${data.apiURL && data.apiURL === "AppAPI" ? apiBaseURL : baseURL}${
          data.url
        }`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...data.params, lang_code: "EN" }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    })
  },
  postFormDataAPI(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}${data.url}`, {
        method: "post",
        headers: {
          'contentType': "application/json",
        },
        body: data.params
      }).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
  currencyConvert(price) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'VND', minimumFractionDigits:2 }).format(Number(price));
  },
};


export default config;
