import React, { useEffect, useState } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import TextInput from "../../components/Form/TextInput";
import SwitchInput from "../../components/Form/SwitchInput";
import labels from "../../constants/Brand";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// For Edit Brand
let Id = "";
function Edit({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const fieldArr = [
    { id: 4, title: "Icon", name: "icon", type: "FileInput" },
    { id: 3, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];
  const [icon, setIcon] = useState(null);
  const [fields, setFields] = useState({
    title: {}
  });
  const [errors, setErrors] = useState({
    title: "",
    status: "",
  });
  const [fieldArr2, setFieldArr2] = useState([]);
  const [language, setLanguage] = useState([])

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'brand/list', params: { brand_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        let titleArr = []
        data.payload.map((singleLang) => {
          titleArr.push({ id: 0, title: `Title [${singleLang.title}] `, name: `title_${singleLang.code}`, type: "TextInput", placeholder: `Enter your title in ${singleLang.title}` })
        })
        setFieldArr2(titleArr)
        setLanguage(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleChange(field, e) {
    if (field === 'icon') {
      setIcon(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field == 'is_two_step_verification') {
      setFields({ ...fields, [field]: e })
    } else if (field.includes("title_")) {
      const lang = field.replace("title_", "")
      const title = {}
      fields.title[lang] = e.target.value
      setFields({ ...fields, [field]: e.target.value })
      return
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  function validation() {
    let flag = true;
    let error = {}
    language.forEach((single) => {
      if (!fields["title_" + single.code]) {
        error["title_" + single.code] = "Please enter  title in " + single.title
        flag = false
      }
    })

    if (!fields.icon) {
      error['icon'] = "Upload your icon"
      flag = false
    }

    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      const formData = new FormData();
      if (icon) {
        formData.append('icon', icon);
      }
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'brand/update', params: formData }).then((data) => {
        if (data.status === 200) {
          toast.success('Record updated successfully');
          return history.push('/brand')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  function cancleImage(field, e) {
    setIcon(null);
    setFields({ ...fields, [field]: '' })
  }
  return (
    <>
      <Breadcrumb module={labels.editBrand} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Edit Brand" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="">
                      <main className="relative">
                        <div className="mx-auto">
                          <div className="">
                            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-1 lg:divide-y-0">
                              <div className="py-2 col-span-10">
                                <section aria-labelledby="applicant-information-title">
                                  <div className="">
                                    <div className="px-4 py-5 sm:px-6">
                                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        {[...fieldArr2, ...fieldArr].map((field) => {
                                          return (
                                            <div className="sm:col-span-3" key={field.id}>
                                              <Label title={field.title} />
                                              <div className="mt-1">
                                                {field.type === "TextInput" ? (
                                                  <TextInput
                                                    id={field.name}
                                                    name={field.name}
                                                    placeholder={field.placeholder}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    is_number={field.is_number || false}
                                                    is_password={field.is_password || false}
                                                  />
                                                ) : field.type === "FileInput" ? (
                                                  <FileInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    cancleImage={cancleImage}
                                                  />
                                                ) : field.type === "Dropdown" ? (
                                                  <Dropdown
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    status={field.options || []}
                                                  />
                                                ) : field.type === "SwitchInput" ? (
                                                  <SwitchInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                  />
                                                ) : field.type === "TextArea" ? (
                                                  <TextArea
                                                    id={field.name}
                                                    name={field.name}
                                                  />
                                                ) : null}
                                              </div>
                                              {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="pt-5 border_color_theme">
                                        <div className="flex justify-end">
                                          <BtnRedirect title="Cancel" path="/brand" />
                                          <BtnSaveRecord handleSubmit={handleSubmit} title="Submit" path="/brand" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit;
