import React, { useState, useEffect, Fragment } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/Trips";

// List all users
function View() {
  const columns = [
    { title: "ID", field: "failure_trip_id" },
    { title: "Customer", field: "customer_info" },
    { title: "Book Date", field: "booking_date" },
    
  ];
  const btnGroups = [
    { id: 2, title: "Export CSV", type: "exportCSV", path: "/user", fileName: "Trips" },
    
  ];
  const [show, setShow] = useState(false);
  const tableRef2 = React.useRef();

  useEffect(() => {
    // console.log(status)
    // tableRef2.current.onQueryChange()
  }, []);

  
  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  }

  return (
    <>
      <Breadcrumb module={labels.Trips}/>
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <ListSection
        captionTitle="Fail Trips"
        columns={columns}
        tableRef={tableRef2}
        // data={data}
        btnGroups={[]}
        filters={false}
        disabledCheckbox={true}
        onSelectionChange={onSelectionChange}
        filterData={{}}
        is_pagenation={true}
        apiURL='trip/list-failure'
      />
    </>
  );
}

export default View;
