import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Country";

// For Edit Country
let Id = "";

function Edit({ history }) {
  const [options, setOptions] = useState([]);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [taxOpt, setTaxOpt] = useState([]);
  const statusOpt = [

    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];
  const fieldArr = [
    { id: 0, title: "Title", name: "name", type: "TextInput", placeholder: "Enter your title" },
    { id: 1, title: "Calling Code", name: "calling_code", type: "TextInput", placeholder: "Enter your calling code" },
    { id: 2, title: "Capital", name: "capital", type: "TextInput", placeholder: "Enter your capital" },
    {
      id: 3,
      title: "Currency",
      name: "currency_id",
      type: "Dropdown",
      options: currencyOpt,
    },
    { id: 4, title: "ISO Code", name: "iso_code", type: "TextInput", isDisable: true, placeholder: "Enter your iso code" },
    {
      id: 6,
      title: "Languages",
      name: "language_id",
      type: "MultiDropdown",
      options: options,
    },
    { id: 7, title: "Tax", name: "tax_id", type: "Dropdown", options: taxOpt },
    {
      id: 8,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    calling_code: "",
    capital: "",
    currency_id: "",
    language_id: "",
    iso_code: "",
    tax_id: "",
    status: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please enter country title"
      flag = false
    }

    if (!fields.calling_code) {
      error['calling_code'] = "Please enter country calling code"
      flag = false
    }
    if (!fields.capital) {
      error['capital'] = "Please enter  country capital"
      flag = false
    }
    if (!fields.currency_id) {
      error['currency_id'] = "Please select currency"
      flag = false
    }
    if (!fields.language_id.length > 0) {
      error['language_id'] = "Please select language"
      flag = false
    }
    if (!fields.iso_code) {
      error['iso_code'] = "Please enter country ISO code"
      flag = false
    }
    if (!fields.tax_id) {
      error['tax_id'] = "Please select tax"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }


  function getTaxDatas() {
    configuration
      .getAPI({ url: "tax/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.label = element.name;
              element.title = element.name;
              element.id = element.tax_id;

              return element;
            });
          }
          setTaxOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function getlanguageDatas() {
    configuration
      .getAPI({ url: "language/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.label = element.title;
              element.value = element.language_id;
              element.id = element.language_id;

              return element;
            });
          }
          setOptions(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getcurrencyDatas() {
    configuration
      .getAPI({ url: "currency/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.currency_id;
              element.id = element.currency_id;

              return element;
            });
          }
          setCurrencyOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "country/list", params: { country_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    getTaxDatas();
    getlanguageDatas();
    getcurrencyDatas();
  }, []);

  function handleChange(field, e) {
    if (field === "language_id") {
      setFields({ ...fields, [field]: e });
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
  }

  // function handleChange(field, e) {
  //   setFields({ ...fields, [field]: e.target.value });
  // }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "country/update", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/country");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editCountry} />
      {/* <AddEditSection title="Edit Country" path="/country" fields={fieldArr}  /> */}
      <AddEditSection
        title="Edit Country"
        path="/country"
        fieldValue={fields}
        fieldError={errors}

        fields={fieldArr}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
