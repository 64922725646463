import React, { useState, useEffect } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck, reject, contains } from "underscore";
import ListSection from "../../components/ListSection";
import labels from "../../constants/Trips";
import configuration from '../../config';
import { toast } from 'react-toastify';
import TripCancleModel from "../../components/TripCancleModel";

// List all users
function View({ history }) {
  const tableRef2 = React.useRef();
  const columns = [
    { title: "ID", field: "invoice_no" },
    { title: "Customer", field: "customer_info" },
    { title: "Driver", field: "driver_info" },
    { title: "Vehicle", field: "vehicle"},
    { title: "Book Date", field: "booking_date" },
    {
      title: "Total",
      field: "total",
      render: (rowData) => (
        <>
          <div
            className="relative mt-20 md:mt-0 "
            onMouseEnter={() => setTooltipStatus(rowData.trip_id)}
            onMouseLeave={() => setTooltipStatus(0)}
          >
            <div className="mr-2 cursor-pointer flex items-center">
              {rowData.total}
              <svg
                aria-haspopup="true"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 icon icon-tabler icon-tabler-info-circle"
                width={25}
                height={25}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#A0AEC0"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={12} cy={12} r={9} />
                <line x1={12} y1={8} x2="12.01" y2={8} />
                <polyline points="11 12 12 12 12 16 13 16" />
              </svg>
            </div>
            {tooltipStatus === rowData.trip_id && (
              <div
                role="tooltip"
                className="z-20 -mt-10 w-64 absolute transition duration-150 ease-in-out right-36 ml-8 shadow-lg bg-white p-4 rounded"
              >
                <div className="">
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">
                      Subtotal
                    </p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.subtotal}
                    </p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">
                      Waiting Charge
                    </p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.waiting_fare}
                    </p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Tax</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.tax}
                    </p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">
                      Commission
                    </p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.commission}
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center justify-between">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={{
                        pathname: `/tripsdetail/${rowData.trip_id}`,
                        state: "accepted-trips",
                      }}
                    >
                      View Detail
                    </Link>
                    <Link
                      to={{
                        pathname: `/invoice/${rowData.trip_id}`,
                        state: "accepted-trips",
                      }}
                      className="inline-flex items-center px-3 py-0.5 rounded-md text-basefont-medium bg-indigo-600 text-white capitalize"
                    >
                      Invoice
                    </Link>
                  </div>
                </div>
              </div>
            )}{" "}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      field: "trip_id",
      render: (rowData) => (
        <span>
          <button
            type="button"
            onClick={(e) => handleAssignTrip(rowData)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          >
            Pickup Trip
          </button>
        </span>
      ),
    },
    {
      /*
      title: "Action",
      field: "id",
      render: (rowData) => (
        <span className='flex'>
          <button
            type="button"
            onClick={(e)=>handleCancleTrip(rowData.trip_id)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          >
            Cancel Trip
          </button>
        </span>
      ),
        */
    },
  ];
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cancleTripId, setCancleTripId] = useState("");

  const btnGroups = [
    {
      id: 2,
      title: "Export CSV",
      type: "exportCSV",
      path: "/user",
      fileName: "Trips",
    },
  ];
  const [show, setShow] = useState(false);
  const [tooltipStatus, setTooltipStatus] = useState(0);

  useEffect(() => {
    // console.log(status)
    // getDatas({status:['accepted']});
  }, []);

  // function getDatas(filterData) {
  //   configuration.getAPI({ url: 'trip/list', params: {filterData: JSON.stringify(filterData)} }).then((data) => {
  //     // console.log(data)
  //     if (data.status === 200) {
  //       setData(data.payload)
  //     }
  //   }).catch(error => {
  //     // console.log(error)
  //     return toast.error(error.message)
  //   });
  // }
  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  };
  function handleAssignTrip(rowData) {
    configuration
      .postAPI({
        url: "trip/change-status",
        apiURL:'AppAPI',
        params: { trip_id: rowData.trip_id, user_id: rowData.driver_id, status:'picked_up' },
      })
      .then((data) => {
        if (data.status === 200) {
          history.push("/started-trips");
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleCancleTrip(trip_id) {
    setCancleTripId(trip_id);
    setShowModal(true);
  }

  function handleConfirm() {
    console.log(cancleTripId);
    configuration
      .postAPI({ url: "trip/cancel", params: { trip_id: cancleTripId } })
      .then((data) => {
        if (data.status === 200) {
          setShowModal(false);
          setCancleTripId("");
          tableRef2.current.onQueryChange();
          // getDatas({status:['accepted']});
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleCancel() {
    setShowModal(false);
    setCancleTripId("");
  }

  return (
    <>
      <Breadcrumb module={labels.Trips} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <TripCancleModel
        mode={showModal}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <ListSection
        captionTitle="Accepted Trips"
        columns={columns}
        tableRef={tableRef2}
        // data={data}
        btnGroups={[]}
        filters={false}
        disabledCheckbox={true}
        onSelectionChange={onSelectionChange}
        filterData={{ status: ["accepted"] }}
        is_pagenation={true}
        apiURL="trip/list-sort"
      />
    </>
  );
}

export default View;
