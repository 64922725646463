import React, { useState, useEffect, Fragment } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck, each, contains } from "underscore";
import ListSection from "../../components/ListSection";
import Label from "../../components/Form/Label";
import labels from "../../constants/Trips";
import configuration from "../../config";
import { toast } from "react-toastify";
import TripCancleModel from "../../components/TripCancleModel";
import DeleteModel from "../../components/DeleteModel";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";

// List all users
function View({ history }) {
  const columns = [
    { title: "ID", field: "invoice_no" },
    { title: "Customer", field: "customer_info" },
    {
      title: "Vehicle",
      field: "vehicle_maker",
      render: (rowData) => (
        <span>
          {rowData.vehicle_maker} {rowData.vehicle_model_code} [
          {rowData.vehicle_color}]
        </span>
      ),
    },
    { title: "Book Date", field: "booking_date" },
    {
      title: "Total",
      field: "total",
      render: (rowData) => (
        <>
          <div
            className="relative mt-20 md:mt-0 "
            onMouseEnter={() => setTooltipStatus(rowData.trip_id)}
            onMouseLeave={() => setTooltipStatus(0)}
          >
            <div className="mr-2 cursor-pointer flex items-center">
              {rowData.total}
              <svg
                aria-haspopup="true"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 icon icon-tabler icon-tabler-info-circle"
                width={25}
                height={25}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#A0AEC0"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={12} cy={12} r={9} />
                <line x1={12} y1={8} x2="12.01" y2={8} />
                <polyline points="11 12 12 12 12 16 13 16" />
              </svg>
            </div>
            {tooltipStatus === rowData.trip_id && (
              <div
                role="tooltip"
                className="z-20 -mt-10 w-64 absolute transition duration-150 ease-in-out right-36 ml-8 shadow-lg bg-white p-4 rounded"
              >
                <div className="">
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">
                      Subtotal
                    </p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.subtotal}
                    </p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">
                      Waiting Charge
                    </p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.waiting_fare}
                    </p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Tax</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.tax}
                    </p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">
                      Commission
                    </p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">
                      {rowData.commission}
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center justify-between">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={{
                        pathname: `/tripsdetail/${rowData.trip_id}`,
                        state: "new-trips",
                      }}
                    >
                      View Detail
                    </Link>
                    <Link
                      to={{
                        pathname: `/invoice/${rowData.trip_id}`,
                        state: "new-trips",
                      }}
                      className="inline-flex items-center px-3 py-0.5 rounded-md text-basefont-medium bg-indigo-600 text-white capitalize"
                    >
                      Invoice
                    </Link>
                  </div>
                </div>
              </div>
            )}{" "}
          </div>
        </>
      ),
    },
    {/*
      title: "Accept",
      field: "trip_id",
      render: (rowData) => (
        <span>
          <button
            type="button"
            onClick={(e) => handleAssignTrip(rowData.trip_id)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          >
            Accept Trip
          </button>
        </span>
      ),
      */},
    {
      title: "Action",
      field: "id",
      render: (rowData) => (
        <span className='flex'>
          {/* <button
            type="button"
            onClick={(e) => handleCancleTrip(rowData.trip_id)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          >
            Cancel Trip
          </button>
          <button
            type="button"
            onClick={(e) => handleDeleteTrip(rowData.trip_id)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          >
            Delete Trip
          </button> */}
          <button
            type="button"
            onClick={(e) => openPopUP(rowData.requestedDrivers)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          >
            Requested Drivers
          </button>
        </span>
      ),
    },
  ];
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [showDriver, setShowDriver] = useState(false);
  const [driverErr, setDriverErr] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState({});
  const btnGroups = [
    {
      id: 2,
      title: "Export CSV",
      type: "exportCSV",
      path: "/user",
      fileName: "Trips",
    },
  ];
  const [show, setShow] = useState(false);
  const [cancleTripId, setCancleTripId] = useState("");
  const [tooltipStatus, setTooltipStatus] = useState(0);
  const tableRef2 = React.useRef();

  useEffect(() => {
    // console.log(status)
    // getDatas({status:['pending']});
    // tableRef2.current.onQueryChange()
    // getDrivers();
  }, []);

  function handleAssignTrip(trip_id) {
    // setCancleTripId(trip_id)
    // setShowDriver(true);
    configuration
      .postAPI({
        url: "trip/accept",
        apiURL: "AppAPI",
        params: { trip_id: trip_id, user_id: "DRI167169492594" },
      })
      .then((data) => {
        if (data.status === 200) {
          history.push("/accepted-trips");
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleAssignDriver() {
    console.log("handleAssignDriver");
    setDriverErr("");
    if (selectedDriver.value && selectedDriver.value !== "") {
      console.log(selectedDriver);
      configuration
        .postAPI({
          url: "trip/assign-driver",
          params: { trip_id: cancleTripId, driver_id: selectedDriver.value },
        })
        .then((data) => {
          if (data.status === 200) {
            setShowDriver(false);
            setSelectedDriver({});
            setCancleTripId("");
            tableRef2.current.onQueryChange();
            // getDatas({status:['pending']});
            // setData(data.payload)
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    } else {
      setDriverErr("Please select driver");
    }
  }

  function handleCancleTrip(trip_id) {
    setCancleTripId(trip_id);
    setShowModal(true);
  }

  function handleDeleteTrip(trip_id) {
    setCancleTripId(trip_id);
    setDeleteShowModal(true);
  }

  function openPopUP(driverList) {
    console.log(driverList);
    setShowDriver(true);
    setDrivers(driverList);
  }

  function handleDelete() {
    configuration
      .postAPI({ url: "trip/delete-trip", params: { trip_id: cancleTripId } })
      .then((data) => {
        if (data.status === 200) {
          setDeleteShowModal(false);
          setCancleTripId("");
          tableRef2.current.onQueryChange();
          // getDatas({status:['pending']});
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleConfirm() {
    // console.log(cancleTripId)
    // setShowModal(false);
    // setCancleTripId('')
    // datareload();
    configuration
      .postAPI({ url: "trip/cancel", params: { trip_id: cancleTripId } })
      .then((data) => {
        if (data.status === 200) {
          setShowModal(false);
          setCancleTripId("");
          tableRef2.current.onQueryChange();
          // getDatas({status:['pending']});
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleCancel() {
    setShowModal(false);
    setDeleteShowModal(false);
    setCancleTripId("");
  }
  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  };

  return (
    <>
      <Breadcrumb module={labels.Trips} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <TripCancleModel
        mode={showModal}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <DeleteModel
        mode={showDeleteModal}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <ListSection
        captionTitle="New Trips"
        columns={columns}
        tableRef={tableRef2}
        // data={data}
        btnGroups={[]}
        filters={false}
        disabledCheckbox={true}
        onSelectionChange={onSelectionChange}
        filterData={{ status: ["pending"] }}
        is_pagenation={true}
        apiURL="trip/list-new-trips-sort"
      />
      <Transition.Root show={showDriver} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => setShowDriver(true)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Trip Requested Drivers
                      </Dialog.Title>
                      <ul role="list" className="divide-y divide-gray-200 mt-2">
                        {drivers.map((person) => (
                          <li key={person.name} className="flex py-4">
                            {/* <img className="h-10 w-10 rounded-full" src={person.image} alt="" /> */}
                            <div className="ml-3">
                              <Link
                                className="text-indigo-600 hover:text-indigo-900"
                                to={{
                                  pathname: `/driver/profile/${person.driver_id}`,
                                  state: "new-trips",
                                }}
                              >
                                <p className="text-sm font-medium text-gray-900">
                                  {person.name}
                                </p>
                                {/* <p className="text-sm text-gray-500">{person.email}</p> */}
                              </Link>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setShowDriver(false);
                      setDrivers([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default View;
