import React, { useState, useEffect } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/PushTemplate";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Add Push Template
function Add({ history }) {
  const [fieldArr, setFieldArr] = useState([])
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [languages, setLanguages] = useState([])

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];
  const isSendOpt = [
    { id: 1, title: "Yes", value: "true" },
    { id: 2, title: "No", value: "false" }
  ];
  const columnArr = [
    { id: 0, title: "Title", name: "title", type: "TextInput", placeholder: "Enter your title" },
    { id: 1, title: "Code", name: "template_code", type: "TextInput", placeholder: "Enter your code" },
    { id: 3, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    getlanguages();
  }, []);

  function getlanguages() {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let valueArr = []
        let captionValueArr = []
        UND.each(data.payload, (lang, ind) => {
          valueArr.push({ id: ind, title: "Value[" + lang.title + "]", name: "value_" + lang.code, type: "TextInput", placeholder: `Enter your value in ${lang.title}` })
          captionValueArr.push({ id: ind, title: "Caption Value[" + lang.title + "]", name: "caption_value_" + lang.code, type: "TextInput", placeholder: `Enter your caption value in ${lang.title}` })
        })
        const fullCoulnmArr = columnArr.concat(valueArr, captionValueArr);
        setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter push template title"
      flag = false
    }

    if (!fields.template_code) {
      error['template_code'] = "Please enter push template code "
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }

    UND.each(languages, (lang, ind) => {
      if (!fields['value_' + lang.code]) {
        error['value_' + lang.code] = "Please enter push template value in " + lang.title
        flag = false
      }
      if (!fields['value_' + lang.code]) {
        error['caption_value_' + lang.code] = "Please enter push template caption value in " + lang.title
        flag = false
      }
    })

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      configuration.postAPI({ url: 'push-template/create', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/push-template')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  return (
    <>
      <Breadcrumb module={labels.addpushTemplate} />
      <AddEditSection
        title="Add Push Template"
        path="/push-template"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
