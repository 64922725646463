import PropTypes from "prop-types";
import React from "react";

// For Input Element
function TextInput({ id, name, handleChange, value, is_number, is_password, is_read_only, placeholder }) {
  return (
    <input
      type={(is_number) ? "number" : (is_password) ? "password" : "text"}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={(e) => handleChange(name, e)}
      readOnly={is_read_only || false}
      autoComplete="given-name"
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
    />
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: "",
  name: "",
};

export default TextInput;
