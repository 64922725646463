
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../parts/Breadcrumb";
import Caption from "../../components/Caption";
import labels from "../../constants/Trips";
import { PaperAirplaneIcon, DownloadIcon, EyeIcon } from '@heroicons/react/solid'
import configuration from '../../config';
import { toast } from 'react-toastify';
import html2pdf from "html2pdf.js";
import { useLocation } from 'react-router-dom'
import { contains } from "underscore";

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// For Edit User
let Id = "";
function Edit({ history }) {
  const [currency, setCurrency] = useState('')
  const [admin_notes, setAdmin_notes] = useState('')
  const [tripDetail, setTripDetail] = useState({
    customer_info: {},
    driver_info: {},
    fare_info: {},
    discount: 0,
    halt_address: [],
    additional_charges: [],
    taxes: [],
  });
  const location = useLocation();
  const path = location.state;
  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'trip/detail', params: { trip_id: Id } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setTripDetail(data.payload)
        setAdmin_notes(data.payload.admin_notes)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setCurrency(data.payload.currency)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);
  function printInvoice() {
    const element = document.getElementById("make_pdf");
    let fileName = tripDetail.trip_id;
    var opt = {
      margin: 1,
      filename: fileName + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  }
  function sendEmail() {
    return toast.success('Send mail successfully');
  }
  function handleSaveNote() {
    configuration.postAPI({ url: "trip/save-note", params: { trip_id: tripDetail.trip_id, admin_notes } }).then((data) => {
      if (data.status === 200) {
        // getDatas({status:['pending']});
        // setData(data.payload)
        return toast.success('Save notes successfully');
      } else if (data.error) {
        return toast.error(data.error.message);
      } else {
        return toast.error("Something went wrong");
      }
    })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  return (
    <>
      <Breadcrumb module={labels.TripsDetail} page={path} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Trips Details" />
                  </div>
                  <div className="mb-5 text-right">
                    <button type="button" onClick={sendEmail} className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                      <span className="sr-only">Open options</span>
                      <PaperAirplaneIcon className="h-5 w-5 inline" aria-hidden="true" /> Send to Customer
                    </button>
                    <button type="button" onClick={printInvoice} className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                      <span className="sr-only">Open options</span>
                      <DownloadIcon className="h-5 w-5 inline" aria-hidden="true" /> Download as PDF
                    </button>
                    <Link to={`/invoice/${tripDetail.trip_id}`}>
                      <button type="button" className="py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                        <span className="sr-only">Open options</span>
                        <EyeIcon className="h-5 w-5 inline" aria-hidden="true" /> Preview Invoice
                      </button></Link>
                  </div>
                  <div id="make_pdf">
                    <section className="grid grid-cols-2 gap-4">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Customer Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Name</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.customer_info.name} </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Email</dt>
                              <dd className="mt-1 text-base text-gray-900 break-words">{tripDetail.customer_info.email}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Mobile No</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.customer_info.mobile_country_code} {tripDetail.customer_info.mobile}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        {(tripDetail.driver_info && tripDetail.driver_info.name) ?
                          <>
                            <div className="px-4 py-5 sm:px-6">
                              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Driver Details
                              </h2>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Name</dt>
                                  <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.name}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Email</dt>
                                  <dd className="mt-1 text-base text-gray-900 break-words">{tripDetail.driver_info.email}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Mobile No</dt>
                                  <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.mobile_country_code} {tripDetail.driver_info.mobile}</dd>
                                </div>
                              </dl>
                            </div>
                          </> : ''
                        }

                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Trip Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Pickup Address</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.pick_up}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Dropoff Address</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.drop_off}</dd>
                            </div>
                            {(tripDetail.halt_address.length) ?
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Halt Address</dt>
                                {
                                  tripDetail?.halt_address.map((e, key) => {
                                    return (<dd className="mt-1 text-base text-gray-900">{key + 1}. {e.location}</dd>)
                                  })
                                }
                              </div> : ''
                            }
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Book Date</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.booking_date}</dd>
                            </div>
                            {(contains(['accepted', 'started', 'picked_up', 'completed'], tripDetail.status)) ?
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Accepted At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.accepted_at}</dd>
                              </div> : ''
                            }
                            {(contains(['started', 'picked_up', 'completed'], tripDetail.status)) ?
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">PickedUp At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.picked_up_at}</dd>
                              </div> : ''
                            }
                            {(contains(['completed', 'started'], tripDetail.status)) ?
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Started At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.started_at}</dd>
                              </div> : ''
                            }
                            {(tripDetail.status === 'completed') ?
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Completed At</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.completed_at}</dd>
                              </div> : ''
                            }
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Distance</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.formatted_total_distance}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Duration</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.formatted_total_duration}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Vehicle</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.vehicle_maker} {tripDetail.vehicle_model_code} [{tripDetail.vehicle_color}]</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Payment Type</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.payment_type}</dd>
                            </div>
                            {/*<div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Payment Status</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.payment}</dd>
                            </div>*/}
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Trip Status</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.status}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Fare Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Driver Fee</dt>
                              <dd className="mt-1 text-base text-gray-900">{configuration.currencyConvert(tripDetail.fare_info.driver_fee)}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Return Fee</dt>
                              <dd className="mt-1 text-base text-gray-900">{configuration.currencyConvert(tripDetail.fare_info.return_fee)}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Waiting Fee</dt>
                              <dd className="mt-1 text-base text-gray-900">{configuration.currencyConvert(tripDetail.fare_info.waiting_fee)}</dd>
                            </div>
                            {tripDetail.total_waiting_mins > 0 &&
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Total Waiting Minutes</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.total_waiting_mins}</dd>
                              </div>
                            }
                          </dl>
                        </div>
                      </div>
                    </section>
                    {/* <div className="grid grid-cols-2 mt-5 gap-4">
                      <div className="bg-white shadow sm:rounded-lg py-4 px-4">
                        <img src={tripDetail.google_map_image} alt="" />
                      </div>
                      <div className="bg-white shadow sm:rounded-lg py-4 px-4">
                        <div>
                          <label htmlFor="comment" className="block text-basefont-medium text-gray-700">
                            Trip Notes
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={4}
                              name="comment"
                              id="comment"
                              onChange={(e) => setAdmin_notes(e.target.value)}
                              value={admin_notes}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md"
                              defaultValue={''}
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <button
                            type="button"
                            onClick={handleSaveNote}
                            className="mr-3 inline-flex items-center px-3 py-2 border border-transparent text-baseleading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Save Notes
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="total">
                      <div className="mt-5 float-right	">
                        <dl style={{ minWidth: '320px', }} className=" space-y-6 pb-5 pt-5">
                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Subtotal</dt>
                            <dd className="text-basefont-medium text-gray-900">{tripDetail.subtotal}</dd>
                          </div>
                          {(tripDetail.is_insurance_taken === 'yes') ?
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Insurance</dt>
                              <dd className="text-basefont-medium text-gray-900">{configuration.currencyConvert(tripDetail.insurance)}</dd>
                            </div> : ''
                          }
                          {(tripDetail.additional_charges.length > 0) ?
                            tripDetail.additional_charges.map((single, indx) => {
                              return (<div className="flex items-center justify-between">
                                <dt className="text-sm">{single.title}</dt>
                                <dd className="text-basefont-medium text-gray-900">{configuration.currencyConvert(single.price)}</dd>
                              </div>)
                            }) : ''
                          }
                          {(tripDetail.waiting_fare > 0) ?
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Waiting Charge</dt>
                              <dd className="text-basefont-medium text-gray-900">{configuration.currencyConvert(tripDetail.waiting_fare)}</dd>
                            </div> : ''
                          }
                          {(tripDetail.taxes.length > 0) ?
                            tripDetail.taxes.map((single, indx) => {
                              return (<div className="flex items-center justify-between">
                                <dt className="text-sm">{single.name} ({single.value}%)</dt>
                                <dd className="text-basefont-medium text-gray-900">{configuration.currencyConvert(single.tax_amount)}</dd>
                              </div>)
                            }) : ''
                          }
                          {(tripDetail.discount) ?
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Discount</dt>
                              <dd className="text-basefont-medium text-gray-900">{configuration.currencyConvert(tripDetail.discount)}</dd>
                            </div> : ''
                          }

                          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                            <dt className="text-base font-medium">Total</dt>
                            <dd className="text-base font-medium text-gray-900">{tripDetail.total}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit;
