import { BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { reactLocalStorage } from 'reactjs-localstorage';
import configuration from './config';
import { toast } from 'react-toastify';

// Add Components
import AddCity from "./pages/City/Add";
import AddCms from "./pages/CMS/Add";
import AddCountry from "./pages/Country/Add";
import AddCurrency from "./pages/Currency/Add";
import AddEmailTemplate from "./pages/EmailTemplate/Add";
import AddHelp from "./pages/Help/Add";
import AddHelpCategory from "./pages/HelpCategory/Add";
import AddLabel from "./pages/Label/Add";
import AddLanguage from "./pages/Language/Add";
import AddPushTemplate from "./pages/PushTemplate/Add";
import AddRole from "./pages/Role/Add";
import AddSmsTemplate from "./pages/SmsTemplate/Add";
import AddState from "./pages/State/Add";
import AddTax from "./pages/Tax/Add";
import AddCustomers from "./pages/Customer/Add";
import AddZipcode from "./pages/Zipcode/Add";
import AddAdminUsers from "./pages/AdminUser/Add";
import AddCancellationReason from "./pages/CancellationReason/Add";
import AddPromotionCode from "./pages/PromotionCode/Add";
import AddDriver from "./pages/Driver/Add";
// import AddTrip from "./pages/Trip/Add";
import AddBrand from "./pages/Brand/Add";
import AddVehicle from "./pages/Vehicle/Add";
import AddRatingCriterias from "./pages/RatingCriterias/Add";
import AddPrice from "./pages/Price/Add";
import AddAdditionalCharge from "./pages/AdditionalCharge/Add";

// Edit Components
import EditCity from "./pages/City/Edit";
import EditCms from "./pages/CMS/Edit";
import EditCountry from "./pages/Country/Edit";
import EditCurrency from "./pages/Currency/Edit";
import EditEmailTemplate from "./pages/EmailTemplate/Edit";
import EditHelp from "./pages/Help/Edit";
import EditHelpCategory from "./pages/HelpCategory/Edit";
import EditLabel from "./pages/Label/Edit";
import EditLanguage from "./pages/Language/Edit";
import EditPushTemplate from "./pages/PushTemplate/Edit";
import EditRole from "./pages/Role/Edit";
import EditSmsTemplate from "./pages/SmsTemplate/Edit";
import EditState from "./pages/State/Edit";
import EditTax from "./pages/Tax/Edit";
import EditCustomers from "./pages/Customer/Edit";
import EditZipcode from "./pages/Zipcode/Edit";
import EditAdminUsers from "./pages/AdminUser/Edit";
import EditCancellationReason from "./pages/CancellationReason/Edit";
import EditPromotionCode from "./pages/PromotionCode/Edit";
import EditDriver from "./pages/Driver/Edit";
// import EditTrip from "./pages/Trip/Edit";
import EditBrand from "./pages/Brand/Edit";
import EditVehicle from "./pages/Vehicle/Edit";
import EditRatingCriterias from "./pages/RatingCriterias/Edit";
import EditPrice from "./pages/Price/Edit";
import EditAdditionalCharge from "./pages/AdditionalCharge/Edit";
import CustomerProfile from "./pages/Customer/Profile";
import DriverProfile from "./pages/Driver/Profile";

// List Components
import City from "./pages/City/View";
import Cms from "./pages/CMS/View";
import Country from "./pages/Country/View";
import Currency from "./pages/Currency/View";
import Dashboard from "./pages/Dashboard/View";
import Sales from "./pages/Sales/View";
import Orders from "./pages/Orders/View";
import EmailTemplate from "./pages/EmailTemplate/View";
import ForgotPassword from "./pages/ForgotPassword/View";
import GuestRoute from "./layout/GuestRoute";
import Help from "./pages/Help/View";
import HelpCategory from "./pages/HelpCategory/View";
import Label from "./pages/Label/View";
import Language from "./pages/Language/View";
import LoginLog from "./pages/LoginLog/View";
import ContactUs from "./pages/ContactUs/View";
import DataBackup from "./pages/DataBackup/View";
import Permission from "./pages/AccessRight/View";
import PersonalSettings from "./pages/Setting/View";
import PushTemplate from "./pages/PushTemplate/View";
import Role from "./pages/Role/View";
import Setting from "./pages/Setting/Menu";
import SignIn from "./pages/SignIn/View";
import OTP from "./pages/OTP/View";
import Reset from "./pages/Reset/View";
import SmsTemplate from "./pages/SmsTemplate/View";
import State from "./pages/State/View";
import Tax from "./pages/Tax/View";
import TemplateRoute from "./layout/TemplateRoute";
import Customers from "./pages/Customer/View";
import Zipcode from "./pages/Zipcode/View";
import PageNotFound from "./pages/404/View";
import AdminUser from "./pages/AdminUser/View";
import NotificationLog from "./pages/Notification/log";
import NotificationSend from "./pages/Notification/send";
import CancellationReason from "./pages/CancellationReason/View";
import PromotionCode from "./pages/PromotionCode/View";
import ApprovedDriver from "./pages/Driver/list/approved-driver";
import UnapprovedDriver from "./pages/Driver/list/unapproved-driver";
import EnrollmentDriver from "./pages/Driver/list/enrollment-driver";
import ActiveDriver from "./pages/Driver/list/active-driver";
import InactiveDriver from "./pages/Driver/list/inactive-driver";
import OnlineDriver from "./pages/Driver/list/online-driver";
import OfflineDriver from "./pages/Driver/list/offline-driver";
import Driver from "./pages/Driver/View";
// import Trip from "./pages/Trip/View";
import Brand from "./pages/Brand/View";
import Vehicle from "./pages/Vehicle/View";
import Price from "./pages/Price/View";
import AdditionalCharge from "./pages/AdditionalCharge/View";
import RatingCriterias from "./pages/RatingCriterias/View";

import Trips from "./pages/Trips/View";
import NewTrips from "./pages/Trips/newTrip";
import FailTrips from "./pages/Trips/failTrip";
import AcceptedTrips from "./pages/Trips/acceptedTrip";
import StartedTrips from "./pages/Trips/startedTrip";
import CompletedTrips from "./pages/Trips/completedTrip";
import CancelledTrips from "./pages/Trips/cancelledTrip";
import NoServiceTrips from "./pages/Trips/noServiceTrip";
import ScheduledTrips from "./pages/Trips/scheduledTrip";
import TripsDetail from "./pages/Trips/TripsDetail";
import Invoice from "./pages/Trips/Invoice";


// Manage Routes
function App() {
  useEffect(() => {
    configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
      if (data.status === 200) {
        document.title = data.payload.app_name
        const dark_mode = document.getElementById('style-direction');
        // dark_mode.href = (reactLocalStorage.get('dark_mode') === 'yes') ? 'css/tailwind-dark.css' : 'css/tailwind-light.css';

        const css_name = document.getElementById('style-color');
        // css_name.href = `/css/${(data.payload.admin_color) ? data.payload.admin_color : data.payload.admin_color}.css`;
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  return (
    <main>
      <Router>
        <Switch>
          <GuestRoute exact path="/" component={SignIn} />
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute exact path="/otp" component={OTP} />
          <GuestRoute exact path="/reset/:id" component={Reset} />
          <TemplateRoute exact path="/dashboard" component={Dashboard} />
          <TemplateRoute exact path="/sales" component={Sales} />
          <TemplateRoute exact path="/orders" component={Orders} />
          <TemplateRoute exact path="/setting" component={Setting} />
          <TemplateRoute
            exact
            path="/personal-settings"
            component={PersonalSettings}
          />
          <TemplateRoute exact path="/role" component={Role} />
          <TemplateRoute exact path="/role/add" component={AddRole} />
          <TemplateRoute exact path="/role/edit/:id" component={EditRole} />
          <TemplateRoute exact path="/language" component={Language} />
          <TemplateRoute exact path="/language/add" component={AddLanguage} />
          <TemplateRoute exact path="/language/edit/:id" component={EditLanguage} />
          <TemplateRoute exact path="/label" component={Label} />
          <TemplateRoute exact path="/label/add" component={AddLabel} />
          <TemplateRoute exact path="/label/edit/:id" component={EditLabel} />
          <TemplateRoute exact path="/tax" component={Tax} />
          <TemplateRoute exact path="/tax/add" component={AddTax} />
          <TemplateRoute exact path="/tax/edit/:id" component={EditTax} />
          <TemplateRoute exact path="/country" component={Country} />
          <TemplateRoute exact path="/country/add" component={AddCountry} />
          <TemplateRoute
            exact
            path="/country/edit/:id"
            component={EditCountry}
          />
          <TemplateRoute exact path="/state" component={State} />
          <TemplateRoute exact path="/state/add" component={AddState} />
          <TemplateRoute exact path="/state/edit/:id" component={EditState} />
          <TemplateRoute exact path="/city" component={City} />
          <TemplateRoute exact path="/city/add" component={AddCity} />
          <TemplateRoute exact path="/city/edit/:id" component={EditCity} />
          <TemplateRoute exact path="/zipcode" component={Zipcode} />
          <TemplateRoute exact path="/zipcode/add" component={AddZipcode} />
          <TemplateRoute
            exact
            path="/zipcode/edit/:id"
            component={EditZipcode}
          />
          <TemplateRoute exact path="/help-category" component={HelpCategory} />
          <TemplateRoute
            exact
            path="/help-category/add"
            component={AddHelpCategory}
          />
          <TemplateRoute
            exact
            path="/help-category/edit/:id"
            component={EditHelpCategory}
          />
          <TemplateRoute exact path="/help" component={Help} />
          <TemplateRoute exact path="/help/add" component={AddHelp} />
          <TemplateRoute exact path="/help/edit/:id" component={EditHelp} />
          <TemplateRoute exact path="/cms" component={Cms} />
          <TemplateRoute exact path="/cms/add" component={AddCms} />
          <TemplateRoute exact path="/cms/edit/:id" component={EditCms} />
          <TemplateRoute exact path="/currency" component={Currency} />
          <TemplateRoute exact path="/currency/add" component={AddCurrency} />
          <TemplateRoute
            exact
            path="/currency/edit/:id"
            component={EditCurrency}
          />
          <TemplateRoute
            exact
            path="/email-template"
            component={EmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/add"
            component={AddEmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/edit/:id"
            component={EditEmailTemplate}
          />
          <TemplateRoute exact path="/push-template" component={PushTemplate} />
          <TemplateRoute
            exact
            path="/push-template/add"
            component={AddPushTemplate}
          />
          <TemplateRoute
            exact
            path="/push-template/edit/:id"
            component={EditPushTemplate}
          />
          <TemplateRoute exact path="/sms-template" component={SmsTemplate} />
          <TemplateRoute
            exact
            path="/sms-template/add"
            component={AddSmsTemplate}
          />
          <TemplateRoute
            exact
            path="/sms-template/edit/:id"
            component={EditSmsTemplate}
          />
          <TemplateRoute exact path="/customer" component={Customers} />
          <TemplateRoute exact path="/customer/add" component={AddCustomers} />
          <TemplateRoute exact path="/customer/edit/:id" component={EditCustomers} />
          <TemplateRoute exact path="/customer/profile/:id" component={CustomerProfile} />

          <TemplateRoute exact path="/contact-us" component={ContactUs} />
          <TemplateRoute exact path="/log" component={LoginLog} />
          <TemplateRoute exact path="/data-backup" component={DataBackup} />
          <TemplateRoute exact path="/permission" component={Permission} />

          <TemplateRoute exact path="/admin-user" component={AdminUser} />
          <TemplateRoute exact path="/admin-user/add" component={AddAdminUsers} />
          <TemplateRoute exact path="/admin-user/edit/:id" component={EditAdminUsers} />

          <TemplateRoute exact path="/notification-log" component={NotificationLog} />
          <TemplateRoute exact path="/notification/send" component={NotificationSend} />

          <TemplateRoute exact path="/cancellation-reason" component={CancellationReason} />
          <TemplateRoute exact path="/cancellation-reason/add" component={AddCancellationReason} />
          <TemplateRoute exact path="/cancellation-reason/edit/:id" component={EditCancellationReason} />

          <TemplateRoute exact path="/promotion-code" component={PromotionCode} />
          <TemplateRoute exact path="/promotion-code/add" component={AddPromotionCode} />
          <TemplateRoute exact path="/promotion-code/edit/:id" component={EditPromotionCode} />

          <TemplateRoute exact path="/driver" component={Driver} />
          <TemplateRoute exact path="/driver/add" component={AddDriver} />
          <TemplateRoute exact path="/driver/edit/:id" component={EditDriver} />
          <TemplateRoute exact path="/driver/profile/:id" component={DriverProfile} />
          <TemplateRoute
            exact
            path="/approved-driver"
            component={ApprovedDriver}
          />
          <TemplateRoute
            exact
            path="/unapproved-driver"
            component={UnapprovedDriver}
          />
          <TemplateRoute
            exact
            path="/enrollment-driver"
            component={EnrollmentDriver}
          />
          <TemplateRoute exact path="/active-driver" component={ActiveDriver} />
          <TemplateRoute
            exact
            path="/inactive-driver"
            component={InactiveDriver}
          />
          <TemplateRoute exact path="/online-driver" component={OnlineDriver} />
          <TemplateRoute
            exact
            path="/offline-driver"
            component={OfflineDriver}
          />

          <TemplateRoute exact path="/brand" component={Brand} />
          <TemplateRoute exact path="/brand/add" component={AddBrand} />
          <TemplateRoute exact path="/brand/edit/:id" component={EditBrand} />

          <TemplateRoute exact path="/vehicle" component={Vehicle} />
          <TemplateRoute exact path="/vehicle/add" component={AddVehicle} />
          <TemplateRoute exact path="/vehicle/edit/:id" component={EditVehicle} />

          <TemplateRoute exact path="/rating-criterias" component={RatingCriterias} />
          <TemplateRoute exact path="/rating-criterias/add" component={AddRatingCriterias} />
          <TemplateRoute exact path="/rating-criterias/edit/:id" component={EditRatingCriterias} />

          <TemplateRoute exact path="/price" component={Price} />
          <TemplateRoute exact path="/price/add" component={AddPrice} />
          <TemplateRoute exact path="/price/edit/:id" component={EditPrice} />

          <TemplateRoute exact path="/additional-charge" component={AdditionalCharge} />
          <TemplateRoute exact path="/additional-charge/add" component={AddAdditionalCharge} />
          <TemplateRoute exact path="/additional-charge/edit/:id" component={EditAdditionalCharge} />

          <TemplateRoute exact path="/trips" component={Trips} />
          <TemplateRoute exact path="/new-trips" component={NewTrips} />
          <TemplateRoute exact path="/fail-trips" component={FailTrips} />
          <TemplateRoute
            exact
            path="/accepted-trips"
            component={AcceptedTrips}
          />
          <TemplateRoute exact path="/started-trips" component={StartedTrips} />
          <TemplateRoute
            exact
            path="/completed-trips"
            component={CompletedTrips}
          />
          <TemplateRoute
            exact
            path="/cancelled-trips"
            component={CancelledTrips}
          />
          <TemplateRoute
            exact
            path="/no-service-trips"
            component={NoServiceTrips}
          />
          <TemplateRoute
            exact
            path="/scheduled-trips"
            component={ScheduledTrips}
          />
          <TemplateRoute
            exact
            path="/tripsdetail/:id"
            component={TripsDetail}
          />
          <TemplateRoute
            exact
            path="/invoice/:id"
            component={Invoice}
          />

          <GuestRoute component={PageNotFound} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
