import React, { useState, useEffect } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import SwitchInput from "../../components/Form/SwitchInput";
import TextInput from "../../components/Form/TextInput";
import labels from "../../constants/CancellationReason";
import configuration from '../../config';
import { toast } from 'react-toastify';

function Add({ history }) {
  const [fieldArr1, setFieldArr1] = useState([]);
  const [errors, setErrors] = useState({ title: "", status: "" });
  const [fields, setFields] = useState({
    title: {}
  });
  const [language, setLanguage] = useState([]);

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];

  const typeOpt = [
    { id: 1, title: "Customer", value: "customer" },
    { id: 2, title: "Driver", value: "driver" },
  ];

  const fieldArr = [
    { id: 1, title: "Type", name: "type", type: "Dropdown", options: typeOpt },
    { id: 2, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  function validation() {
    let flag = true;
    let error = {}
    language.forEach((single) => {
      if (!fields["title_" + single.code]) {
        error["title_" + single.code] = "Please enter valid title in " + single.title
        flag = false
      }
    })

    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }
    if (!fields.type) {
      error['type'] = "Please select type"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      configuration.postAPI({ url: 'cancellation-reason/create', params: fields }).then((data) => {
        if (data.status === 200) {
          toast.success('Record added successfully')
          return history.push('/cancellation-reason')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        let titleArr = []
        data.payload.map((singleLang) => {
          titleArr.push({ id: singleLang.language_id, title: `Title [${singleLang.title}]`, name: `title_${singleLang.code}`, type: "TextInput", placeholder: `Enter your title in ${singleLang.title}` })
        })
        setLanguage(data.payload)
        setFieldArr1(titleArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleChange(field, e) {
    if (field.includes("title_")) {
      const lang = field.replace("title_", "")
      const title = {}
      fields.title[lang] = e.target.value
      setFields({ ...fields, [field]: e.target.value })
      return
    }
    setFields({ ...fields, [field]: e.target.value })
    return
  }
  return (
    <>
      <Breadcrumb module={labels.addcancellationReason} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Add Cancellation Reason" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              {[...fieldArr1, ...fieldArr].map((field) => {
                                return (
                                  <div className="sm:col-span-3" key={field.id}>
                                    <Label title={field.title} />
                                    <div className="mt-1">
                                      {field.type === "TextInput" ? (
                                        <TextInput
                                          id={field.name}
                                          handleChange={handleChange}
                                          placeholder={field.placeholder}
                                          value={fields[field.name]}
                                          name={field.name}
                                          is_number={field.is_number || false}
                                        />
                                      ) : field.type === "FileInput" ? (
                                        <FileInput
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                        />
                                      ) : field.type === "Dropdown" ? (
                                        <Dropdown
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                          status={field.options || []}
                                        />
                                      ) : field.type === "SwitchInput" ? (
                                        <SwitchInput
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                        />
                                      ) : field.type === "TextArea" ? (
                                        <TextArea
                                          id={field.name}
                                          name={field.name}
                                        />
                                      ) : null}
                                    </div>
                                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="pt-5 border_color_theme">
                          <div className="flex justify-end">
                            <BtnRedirect title="Cancel" path="/cancellation-reason" />
                            <BtnSaveRecord handleSubmit={handleSubmit} title="Submit" path="/cancellation-reason" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Add;
