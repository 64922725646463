import React, { useState, useEffect } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import SwitchInput from "../../components/Form/SwitchInput";
import TextInput from "../../components/Form/TextInput";
import DatePicker from "../../components/Form/DatePicker";
import labels from "../../constants/Driver";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { findWhere } from "underscore";
let Id = "";
function Edit({ history }) {
    const statusOpt = [
        { id: 1, title: "Active", value: "active" },
        { id: 2, title: "Inactive", value: "inactive" },
        // { id: 3, title: "Archive", value: "archive" },
    ];
    const [countryCode, setCountryCode] = useState([])
    const [stateOpt, setStateOpt] = useState([]);
    const [cityOpt, setCityOpt] = useState([]);
    const fieldArr = [
        { id: 0, title: "Name", name: "name", type: "TextInput", placeholder: "Enter your name" },
        { id: 1, title: "Email", name: "email", type: "TextInput", placeholder: "Enter your email id" },
        { id: 2, title: "Mobile Country Code", name: "mobile_country_code", type: "Dropdown", options: countryCode },
        { id: 3, title: "Mobile", name: "mobile", type: "TextInput", is_number: true, placeholder: "Enter your mobile number" },
        { id: 4, title: "Date of Birthday", name: "dob", type: "DateInput" },
        {
            id: 5,
            title: "State",
            name: "state_id",
            type: "Dropdown",
            options: stateOpt,
        },
        {
            id: 6,
            title: "City",
            name: "city_id",
            type: "Dropdown",
            options: cityOpt,
        },
        { id: 7, title: "Address", name: "address", type: "TextInput", placeholder: "Enter your address" },
        { id: 8, title: "Photo", name: "photo", type: "FileInput" },
        { id: 9, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
        { id: 10, title: "Company Earning(%)", name: "company_earning", type: "TextInput", is_number: true },
        // { id: 11, title: "Waiting Charge", name: "waiting_charge", type: "TextInput", is_number: true },
    ];

    const [fieldArr1, setFieldArr1] = useState([]);
    const [errors, setErrors] = useState({
        name: "",
        mobile: "",
        email: "",
        status: "",
    });
    const [photo, setPhoto] = useState(null);
    const [fields, setFields] = useState({});

    function validation() {
        let flag = true;
        let error = {}
        const mobileRegex = /^(\+\d{1,2})?\(?\d{2,3}\)?\d{7}$/g;
        const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        const passRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if (!emailRegex.test(fields.email)) {
            error["email"] = "Please enter valid email id";
            flag = false;
        }

        if (!mobileRegex.test(fields.mobile)) {
            error['mobile'] = "Please enter valid mobile number"
            flag = false
        }
        if (!fields.mobile_country_code) {
            error['mobile_country_code'] = "Please select mobile country code"
            flag = false
        }

        if (!fields.mobile) {
            error['mobile'] = "Please enter your mobile number"
            flag = false
        }

        if (!fields.email) {
            error['email'] = "Please enter your email id"
            flag = false
        }

        if (!fields.name) {
            error['name'] = "Please enter your name"
            flag = false
        }

        if (!fields.address) {
            error['address'] = "Plese enter your address"
            flag = false
        }

        if (!fields.photo) {
            error['photo'] = "Uploade your photo"
            flag = false
        }

        if (!fields.state_id) {
            error['state_id'] = "Please select state"
            flag = false
        }

        if (!fields.city_id) {
            error['city_id'] = "Please select city"
            flag = false
        }

        if (!fields.status) {
            error['status'] = "Please select any one status"
            flag = false
        }

        if (fields.company_earning < 0 || fields.company_earning >= 100) {
            error['company_earning'] = "Please enter valid earning percentage"
            flag = false
        }

        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () => {
        if (validation()) {
            const formData = new FormData();
            if (photo) {
                formData.append('photo', photo);
            }

            formData.append('fields', JSON.stringify({ fields, lang_code: 'EN' }));
            configuration.postFormDataAPI({ url: 'driver/update', params: formData }).then((data) => {
                if (data.status === 200) {
                    toast.success('Record added successfully')
                    return history.goBack()
                } else if (data.error) {
                    return toast.error(data.error.message)
                } else {
                    return toast.error('Something went wrong')
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({ url: 'driver/list', params: { driver_id: Id } }).then((data) => {
            if (data.status === 200) {
                setFields(data.payload)
                configuration.getAPI({ url: 'country/list' }).then((data2) => {
                    if (data2.status === 200) {
                        let codeArr = []
                        data2.payload.map((singleCode) => {
                            codeArr.push({ id: singleCode.country_id, title: `${singleCode.calling_code} ${singleCode.name}`, value: `${singleCode.calling_code}` })
                        });
                        setCountryCode(codeArr)
                        let countryData = findWhere(data2.payload, { calling_code: data.payload.mobile_country_code })
                        getStateDatas({ country_id: countryData.country_id });
                    }
                }).catch(error => {
                    return toast.error(error.message)
                });
                getCityDatas({ state_id: data.payload.state_id });
            }
        }).catch(error => {
            return toast.error(error.message)
        });
    }, []);

    function handleChange(field, e) {
        if (field === 'photo') {
            setPhoto(e.target.files[0]);
            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
        } else if (field === 'is_two_step_verification' || field === 'dob') {
            setFields({ ...fields, [field]: e })
        } else if (field === "mobile_country_code") {
            console.log(e.target.value)
            if (e.target.value) {
                let countryData = findWhere(countryCode, { value: e.target.value })
                console.log(countryData)
                getStateDatas({ country_id: countryData.id });
            } else {
                setStateOpt([]);
            }
            setCityOpt([]);
            setFields({ ...fields, state_id: '', city_id: '', [field]: e.target.value });
        } else if (field === "state_id") {
            console.log(e.target.value)
            if (e.target.value) {
                getCityDatas({ state_id: e.target.value });
            } else {
                setCityOpt([]);
            }
            setFields({ ...fields, city_id: '', [field]: e.target.value });
        } else {
            setFields({ ...fields, [field]: e.target.value })
        }
    }

    function getStateDatas(params) {
        configuration
            .getAPI({ url: "state/list", params })
            .then((data) => {
                if (data.status === 200) {
                    if (data.payload.length > 0) {
                        data.payload.forEach((element) => {
                            element.title = element.name;
                            element.value = element.state_id;
                            element.id = element.state_id;

                            return element;
                        });
                    }
                    setStateOpt(data.payload);
                }
            })
            .catch((error) => {
                return toast.error(error.message);
            });
    }

    function getCityDatas(params) {
        configuration
            .getAPI({ url: "city/list", params })
            .then((data) => {
                if (data.status === 200) {
                    if (data.payload.length > 0) {
                        data.payload.forEach((element) => {
                            element.title = element.name;
                            element.value = element.city_id;
                            element.id = element.city_id;

                            return element;
                        });
                    }
                    setCityOpt(data.payload);
                }
            })
            .catch((error) => {
                return toast.error(error.message);
            });
    }

    function cancleImage(field, e) {
        setPhoto(null);
        setFields({ ...fields, [field]: '' })
    }

    return (
        <>
            <Breadcrumb module={labels.editDriver} />
            <div className="py-6 sm:px-6">
                <div className="relative">
                    <section className="px-4" aria-labelledby="contact-heading">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                                        <Caption title="Edit Driver" />
                                    </div>
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                                        <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                                            <form className="space-y-8 divide-y divide-gray-200">
                                                <div className="space-y-8 divide-y divide-gray-200">
                                                    <div className="pt-1">
                                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            {[...fieldArr1, ...fieldArr].map((field) => {
                                                                return (
                                                                    <div className="sm:col-span-3" key={field.id}>
                                                                        <Label title={field.title} />
                                                                        <div className="mt-1">
                                                                            {field.type === "TextInput" ? (
                                                                                <TextInput
                                                                                    id={field.name}
                                                                                    placeholder={field.placeholder}
                                                                                    handleChange={handleChange}
                                                                                    value={fields[field.name]}
                                                                                    name={field.name}
                                                                                    is_number={field.is_number || false}
                                                                                />
                                                                            ) : field.type === "FileInput" ? (
                                                                                <FileInput
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    handleChange={handleChange}
                                                                                    value={fields[field.name]}
                                                                                    cancleImage={cancleImage}
                                                                                />
                                                                            ) : field.type === "Dropdown" ? (
                                                                                <Dropdown
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    handleChange={handleChange}
                                                                                    value={fields[field.name]}
                                                                                    status={field.options || []}
                                                                                />
                                                                            ) : field.type === "SwitchInput" ? (
                                                                                <SwitchInput
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    handleChange={handleChange}
                                                                                    value={fields[field.name]}
                                                                                />
                                                                            ) : field.type === "TextArea" ? (
                                                                                <TextArea
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                />
                                                                            ) : field.type === "DateInput" ? (
                                                                                <DatePicker
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    handleChange={handleChange}
                                                                                    disableFuture={true}
                                                                                    value={fields[field.name]}
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-5 border_color_theme">
                                                    <div className="flex justify-end">
                                                        <BtnRedirect title="Cancel" path={-1} />
                                                        <BtnSaveRecord handleSubmit={handleSubmit} title="Submit" path="/driver" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Edit;
