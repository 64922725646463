import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import DataTable from "../../../components/MDataTable";
import DatePicker from "../../../components/Form/DatePicker";
import Label from "../../../components/Form/Label";
import { reject, contains } from "underscore";
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary() {
  // const [trips, setTrips] = useState([]);
  // const columns = [
  //     { title: "Date", field: "work_history_id"},
  //     {
  //         title: "Online",
  //         field: "online_at",
  //     },
  //     {
  //         title: "Offline",
  //         field: "offline_at",
  //     },
  //     { title: "Total Hours", field: "total_online_seconds" },
  // ];
  const [data, setData] = useState([]);
  const fieldArr = [
    { id: 3, title: "From  Date", name: "from_date", type: "DatePicker", disableFuture: true },
    { id: 4, title: "To Date", name: "to_date", type: "DatePicker", disableFuture: true },
  ]
  const [fieldsFilter, setFieldsFilter] = useState({
    from_date: null,
    to_date: null
  });
  const [errorsFilter, setErrorsFilter] = useState({
    from_date: '',
    to_date: ''
  });

  const onSelectionChange = (e) => {
    // let idArr = pluck(e, 'user_id');
    // setIds(idArr)
  }
  useEffect(() => {
    getDatas({})
  }, []);
  function getDatas(filterData) {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'driver/earning', params: { filterData: JSON.stringify(filterData), driver_id: Id } }).then((data) => {
      if (data.status === 200) {
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleApplyFilter() {
    if (handleFilterValidation()) {
      getDatas(fieldsFilter)
    }
  }
  function handleClearFilter() {
    setFieldsFilter({ status: [], from_date: null, to_date: null })
    getDatas({})
  }
  function handleChangeFilter(field, e) {
    if (field === 'status') {
      console.log(field)
      console.log(e.target.value)
      let sessionData = (fieldsFilter.status) ? fieldsFilter.status : []
      if (!contains(sessionData, e.target.value)) {
        sessionData.push(e.target.value)
      } else {
        sessionData = reject(sessionData, function (num) { return num === e.target.value; })
      }
      console.log(sessionData)
      setFieldsFilter({ ...fieldsFilter, [field]: sessionData })
      if (sessionData && sessionData.length <= 0) {
        delete fieldsFilter.status;
        getDatas({ fieldsFilter })
      } else {
        getDatas({ ...fieldsFilter, [field]: sessionData })
      }
    } else {
      setFieldsFilter({ ...fieldsFilter, [field]: e })
    }
  }
  function handleFilterValidation() {
    let flag = true;
    let error = {}
    if (fieldsFilter.to_date && !fieldsFilter.from_date) {
      error['from_date'] = "Please select from date"
      flag = false
    }
    if (!fieldsFilter.to_date) {
      error['from_date'] = "Please select to date"
      flag = false
    }
    if (!fieldsFilter.from_date) {
      error['from_date'] = "Please select from date"
      flag = false
    }
    if (fieldsFilter.from_date && !fieldsFilter.to_date) {
      error['to_date'] = "Please select to date"
      flag = false
    }
    if (fieldsFilter.from_date && fieldsFilter.to_date) {
      if (fieldsFilter.to_date < fieldsFilter.from_date) {
        flag = false;
        error["to_date"] = "Please select end date greater than to start date.";
      }
    }
    if (fieldsFilter.status && fieldsFilter.status.length <= 0) {
      delete fieldsFilter.status;
      setFieldsFilter(fieldsFilter)
    }
    setErrorsFilter({ ...error })
    return flag;
  }
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white box_bg shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900 text_theme">
              Earning
            </h2>
            <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver earnings.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-3 mt-5">
              {fieldArr.map((field, index) => {
                return (
                  <div className="" key={index}>
                    <Label title={field.title} />
                    <div className="mt-1 relative">
                      {field.type === "DatePicker" ? (
                        <DatePicker
                          id={field.name}
                          name={field.name}
                          disableFuture={field.disableFuture}
                          handleChange={(name, e) => handleChangeFilter(name, e)}
                          value={fieldsFilter[field.name]}
                        />
                      ) : null}
                    </div>
                    {errorsFilter[field.name] ? <Label title={field.title} fieldError={errorsFilter[field.name]} /> : null}
                  </div>
                );
              })}
            </div>
            <div className="py-2">
              <button
                onClick={handleApplyFilter}
                type="button"
                className="mt-3 mr-3 inline-flex items-center px-3 py-2 border border-transparent text-baseleading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Apply
              </button>
              <button
                onClick={handleClearFilter}
                type="button"
                className="mt-3 inline-flex items-center px-3 py-2 border border-1 text-baseleading-4 font-medium rounded-md shadow-sm text-indigo-600 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Clear
              </button>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div className="grid grid-cols-2 mb-5 mt-6 gap-6">
                <h3 className="sr-only">Review data</h3>
                {data.map((person) => (
                  <div key={person.trip_id} className="bg-white box_bg rounded-lg shadow divide-y divide-gray-200">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1 truncate">
                        <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">{person.booking_date}</p>
                        <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">#{person.trip_id}</p>
                      </div>
                      <div>
                        <h3 className=" flex items-center text-indigo-600 text-lg font-bold sm:mr-6 sm:mt-0 capitalize py-1">{person.driver_commission}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
