import React, { useState, useEffect } from "react";
import { StarIcon } from '@heroicons/react/solid'
import configuration from '../../../config';
import { toast } from 'react-toastify';
import StarsRating from 'stars-rating'

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
let Id = "";
// List Settings
function Summary() {
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [avgCount, setAvgCount] = useState([{ rating: 5, count: 0 },
      { rating: 4, count: 0 },
      { rating: 3, count: 0 },
      { rating: 2, count: 0 },
      { rating: 1, count: 0 },]);
    const [reviewData, setReviewData] = useState([]);
    const [average, setAverage] = useState(0);
    useEffect(() => {
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        getLog()
        // console.log(configuration.baseURL);
    }, []);

    function getLog(){
        configuration.getAPI({url:'user/list', params:{customer_id:Id}}).then((data) => {
          if(data.status === 200){
            setAverage(data.payload.average_ratings)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        configuration.getAPI({url:'user/list-ratings', params:{customer_id:Id}}).then((data) => {
          if(data.status === 200){
            setData(data.payload.criterias || [])
            setReviewData(data.payload.tripsArr || [])
            let counts = [
              { rating: 5, count: data.payload.five_star },
              { rating: 4, count: data.payload.four_star },
              { rating: 3, count: data.payload.three_star },
              { rating: 2, count: data.payload.two_star },
              { rating: 1, count: data.payload.one_star },
            ]
            setAvgCount(counts)
            setTotalCount(data.payload.total_ratings)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
    }

    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white box_bg shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900 text_theme">
                            Customer Reviews
                        </h2>
                        {/*<h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Customer Reviews</h2>*/}
                        {/*<p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>*/}
                        <div className="mt-3 flex items-center">
                            <div>
                              <div className="flex items-center">
                                {[0, 1, 2, 3, 4].map((rating) => (
                                  <StarIcon
                                    key={rating}
                                    className={classNames(
                                      average > rating ? 'text-indigo-600' : 'text-gray-300',
                                      'flex-shrink-0 h-8 w-8'
                                    )}
                                    aria-hidden="true"
                                  />
                                ))}
                              </div>
                              <p className="sr-only">{average} out of 5 stars</p>
                            </div>
                            <p className="ml-2 text-base text-gray-900">Based on {totalCount} reviews</p>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="grid grid-cols-6 mb-5">                     
                            <div className="col-span-3">
                                <div className="mt-6">
                                    <h3 className="sr-only">Review data</h3>
                                    <dl className="space-y-3">
                                      {avgCount.map((count) => (
                                        <div key={count.rating} className="flex items-center text-sm">
                                          <dt className="flex-1 flex items-center">
                                            <p className="w-3 font-medium text-gray-900">
                                              {count.rating}
                                              <span className="sr-only"> star reviews</span>
                                            </p>
                                            <div aria-hidden="true" className="ml-1 flex-1 flex items-center">
                                              <StarIcon
                                                className={classNames(
                                                  count.count > 0 ? 'text-indigo-600' : 'text-gray-300',
                                                  'flex-shrink-0 h-5 w-5'
                                                )}
                                                aria-hidden="true"
                                              />

                                              <div className="ml-3 relative flex-1">
                                                <div className="h-3 bg-gray-100 border border-gray-200 rounded-full" />
                                                {count.count > 0 ? (
                                                  <div
                                                    className="absolute inset-y-0 bg-indigo-600 border rounded-full"
                                                    style={{ width: `calc(${count.count} / ${totalCount} * 100%)` }}
                                                  />
                                                ) : null}
                                              </div>
                                            </div>
                                          </dt>
                                          <dd className="ml-3 w-10 text-right tabular-nums text-base text-gray-900">
                                            {(count.count)?Math.round((count.count / totalCount) * 100):0}%
                                          </dd>
                                        </div>
                                      ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                        
                        {/* <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                            {data.map((person) => (
                                <li key={person.rating_criteria_id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <img className="mb-3" className="h-16 w-16" src={(person.icon)?person.icon:"/image/avatar.png"}></img>
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="text-gray-900 text-lg font-bold truncate pt-2">{person.title}</h3>
                                            </div>
                                            <StarsRating className="text-indigo-600" count={5} value={Number(person.average_ratings)} half={true} edit={false} size={30} color2={'text-indigo-600'} />
                                        </div>
                                        <h3 className="text-indigo-600 text-lg font-bold">{person.total}</h3>
                                    </div>

                                </li>
                            ))}
                        </ul> */}
                        <ul role="list" className="pt-6 grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                            {reviewData.map((person) => (
                                <li key={person.trip_id} className="col-span-1 bg-white box_bg rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        {/*<h3 className="text-gray-900 text-lg font-bold truncate">{person.total}</h3>*/}
                                        <img className="mb-3 h-12 w-12 rounded-full" src={(person.photo)?person.photo:"/image/avatar.png"}></img>
                                        <div className="flex-1">
                                            {/*<span className="text-indigo-600 text-base font-medium  ">
                                                {person.icon}
                                            </span>*/}
                                            <div className="flex items-center space-x-3">
                                                <h3 className="text-gray-900 text-lg font-bold truncate pt-2">{person.name}</h3>
                                            </div>
                                            <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">#{person.trip_id}</p>
                                            {(person.experience && person.experience !== '')?<p class="line-clamp-2 mt-3 text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">{person.experience}</p>:null}
                                            <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">{person.booking_date}</p>
                                            <div className="flex items-center space-x-3">
                                              {person?.driver_to_customer_criterias.map((criteria)=>{
                                                return <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">{criteria.title}</span>
                                              })}
                                            </div>
                                        </div>
                                        <StarsRating className="text-indigo-600" count={5} value={Number(person.ratings)} half={true} edit={false} size={30} color2={'text-indigo-600'} /><br/>
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
