import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import moment from "moment";

let Id = "";
// List Settings
function Summary() {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'driver/list', params: { driver_id: Id } }).then((data) => {
      if (data.status === 200) {
        let profileData = {
          'Name': data.payload.name,
          'Email ': data.payload.email,
          'Mobile': `${data.payload.mobile_country_code} ${data.payload.mobile}`,
          'Reg Date': moment(data.payload.created_at).format('DD-MM-YYYY'),
          'Status': data.payload.status,
          'Overall rating': data.payload.average_ratings,
        }
        if (data.payload && data.payload.device_details?.device_name) {
          profileData['Device Name'] = data.payload.device_details.device_name
        }
        if (data.payload && data.payload.device_details?.device_type) {
          profileData['Device Type'] = data.payload.device_details.device_type
        }
        if (data.payload && data.payload.device_details?.device_token) {
          profileData['Device Token'] = data.payload.device_details.device_token
        }
        if (data.payload && data.payload.device_details?.app_version) {
          profileData['app_version Token'] = data.payload.device_details.app_version
        }
        setProfile(profileData)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white box_bg shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900 text_theme">
              Summary
            </h2>
            <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver summary.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            < div className="">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                {Object.keys(profile).map((field) => (
                  <div key={field} className="sm:col-span-1">
                    <dt className="text-basefont-medium text-gray-500">{field}</dt>
                    <dd className="mt-1 text-base text-gray-900 md:break-all text_theme">{profile[field]}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Summary;
