import React, { useState, useEffect } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import SwitchInput from "../../components/Form/SwitchInput";
import TextInput from "../../components/Form/TextInput";
import labels from "../../constants/Vehicle";
import configuration from '../../config';
import { toast } from 'react-toastify';
import DatePicker from "../../components/Form/DatePicker";

function Add({ history }) {
  const [brandOpt, setBrandOpt] = useState([]);
  const [compulInsuranceDoc, setCompulInsuranceDoc] = useState(null);
  const [nonCompulInsuranceDoc, setNonCompulInsuranceDoc] = useState(null);
  const [certificateOfRegistryDoc, setCertificateOfRegistryDoc] = useState(null);
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const fieldArr = [
    { id: 1, title: "Name", name: "name", type: "TextInput" },
    { id: 2, title: "Brand", name: "brand_id", type: "Dropdown", options: brandOpt },
    { id: 3, title: "Model Code", name: "model_code", type: "TextInput" },
    { id: 4, title: "Color", name: "color", type: "TextInput" },
    { id: 5, title: "Register Date", name: "register_date", type: "Date" },
    { id: 6, title: "License Plate", name: "license_plate", type: "TextInput" },
    { id: 7, title: "Compulsory Insurance", name: "compul_insurance_doc", type: "FileInput" },
    { id: 8, title: "Compulsory Insurance Expiration Date", name: "compul_insurance_expiry_date", type: "Date" },
    { id: 9, title: "Non Compulsory Insurance", name: "non_compul_insurance_doc", type: "FileInput" },
    { id: 10, title: "Non Compulsory Insurance Expiration Date", name: "non_compul_insurance_expiry_date", type: "Date" },
    { id: 11, title: "Certificate of Registry", name: "certificate_of_registry_doc", type: "FileInput" },
    { id: 12, title: "Certificate Expiration Date", name: "certificate_expiry_date", type: "Date" },
    { id: 13, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  const [errors, setErrors] = useState({
    name: "",
    brand_id: "",
    model_code: "",
    color: "",
    register_date: "",
    license_plate: "",
    compul_insurance_doc: "",
    compul_insurance_expiry_date: "",
    certificate_of_registry_doc: "",
    certificate_expiry_date: ""
  });

  const [fields, setFields] = useState({});

  function validation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please enter name"
      flag = false
    }

    if (!fields.brand_id) {
      error['brand_id'] = "Please select brand"
      flag = false
    }

    if (!fields.model_code) {
      error['model_code'] = "Please enter model code"
      flag = false
    }

    if (!fields.color) {
      error['color'] = "Please enter color"
      flag = false
    }

    if (!fields.register_date) {
      error['register_date'] = "Please select register date"
      flag = false
    }

    if (!fields.license_plate) {
      error['license_plate'] = "Please enter license plate"
      flag = false
    }

    if (!fields.compul_insurance_doc) {
      error['compul_insurance_doc'] = "Please upload compulsory insurance"
      flag = false
    }

    if (!fields.compul_insurance_expiry_date) {
      error['compul_insurance_expiry_date'] = "Please select insurance expiration date"
      flag = false
    }

    if (!fields.certificate_of_registry_doc) {
      error['certificate_of_registry_doc'] = "Please upload certificate of registry"
      flag = false
    }

    if (!fields.certificate_expiry_date) {
      error['certificate_expiry_date'] = "Please select certificate expiration date"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      const formData = new FormData();
      if (compulInsuranceDoc) {
        formData.append('compul_insurance_doc', compulInsuranceDoc);
      }
      if (nonCompulInsuranceDoc) {
        formData.append('non_compul_insurance_doc', nonCompulInsuranceDoc);
      }
      if (certificateOfRegistryDoc) {
        formData.append('certificate_of_registry_doc', certificateOfRegistryDoc);
      }
      formData.append('fields', JSON.stringify({ fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'vehicle/create', params: formData }).then((data) => {
        if (data.status === 200) {
          toast.success('Record added successfully')
          return history.push('/vehicle')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  useEffect(() => {
    configuration.getAPI({ url: 'brand/list' }).then((data) => {
      if (data.status === 200) {
        let titleArr = []
        data.payload.map((singleLang) => {
          titleArr.push({ id: singleLang.brand_id, title: `${singleLang.title['EN']}`, value: `${singleLang.brand_id}` })
        })
        setBrandOpt(titleArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleChange(field, e) {
    if (field == 'compul_insurance_doc') {
      setCompulInsuranceDoc(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    }
    else if (field == 'non_compul_insurance_doc') {
      setNonCompulInsuranceDoc(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    }
    else if (field == 'certificate_of_registry_doc') {
      setCertificateOfRegistryDoc(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    }
    else if (field == 'register_date' || field == 'compul_insurance_expiry_date' || field == 'non_compul_insurance_expiry_date' || field == 'certificate_expiry_date') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  function cancleImage(field, e) {
    if (field == 'compul_insurance_doc') {
      setCompulInsuranceDoc(null);
    }
    else if (field == 'non_compul_insurance_doc') {
      setNonCompulInsuranceDoc(null);
    }
    else if (field == 'certificate_of_registry_doc') {
      setCertificateOfRegistryDoc(null);
    }
    setFields({ ...fields, [field]: '' })
  }

  return (
    <>
      <Breadcrumb module={labels.addVehicle} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Add Vehicle" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              {[...fieldArr].map((field) => {
                                return (
                                  <div className="sm:col-span-3" key={field.id}>
                                    <Label title={field.title} />
                                    <div className="mt-1">
                                      {field.type === "TextInput" ? (
                                        <TextInput
                                          id={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                          name={field.name}
                                          is_number={field.is_number || false}
                                        />
                                      ) : field.type === "FileInput" ? (
                                        <FileInput
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                          cancleImage={cancleImage}
                                        />
                                      ) : field.type === "Dropdown" ? (
                                        <Dropdown
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                          status={field.options || []}
                                        />
                                      ) : field.type === "SwitchInput" ? (
                                        <SwitchInput
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                        />
                                      ) : field.type === "TextArea" ? (
                                        <TextArea
                                          id={field.name}
                                          name={field.name}
                                        />
                                      ) : field.type === "Date" ? (
                                        <DatePicker
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields[field.name]}
                                        />
                                      ) : null}
                                    </div>
                                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="pt-5 border_color_theme">
                          <div className="flex justify-end">
                            <BtnRedirect title="Cancel" path="/brand" />
                            <BtnSaveRecord handleSubmit={handleSubmit} title="Submit" path="/brand" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Add;
