import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import DeleteModel from "../../components/DeleteModel";
import labels from "../../constants/DataBackup";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    { title: "ID", field: "id" },
    { title: "Name", field: "name" },
    { title: "Size", field: "size" },
    {
      title: "Download",
      field: "url",
      render: (rowData) => (
        <a className="text-indigo-600 hover:text-indigo-900" href={rowData.url} download> Download</a>
      ),
    },
    {
      title: "Remove",
      field: "url",
      render: (rowData) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="text-indigo-600 hover:text-indigo-900" onClick={() => handleConfirmDelete(rowData)}> Delete</a>
      ),
    },
  ];

  const btnGroups = [{ title: "Create Backup", type: "makeActive", path: "/backup" },];
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function handleAction(type) {
    configuration.postAPI({url:'db-backup/create', params:{}}).then((data) => {
      if(data.status === 200){
        // setData(data.payload)
        handleList();
        return toast.success("Database Backup Created Successfully");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleConfirmDelete(row){
    setRowData(row);
    setShowModal(true);
  }
  function handleDelete(){
    configuration.postAPI({url:'db-backup/remove', params:{filename:rowData.name, url:rowData.url}}).then((data) => {
      if(data.status === 200){
        setRowData(null);
        setShowModal(false);
        handleList();
        return toast.success("Database Backup Deleted Successfully");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleCancel() {
    setShowModal(false);
  }
  function handleRestore(row){
    configuration.postAPI({url:'db-backup/restore', params:{filename:row.name, url:row.url}}).then((data) => {
      if(data.status === 200){
        setData(data.payload)
        return toast.success("Database Backup Restore Successfully");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  useEffect(() => {
    handleList();
  }, []);

  function handleList(){
    configuration.getAPI({url:'db-backup/list', params:{}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });

  }

  return (
    <>
      <Breadcrumb module={labels.dataBackup} />
      <DeleteModel
        mode={showModal}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <ListSection
        captionTitle="View Database Backup"
        columns={columns}
        data={data}
        handleAction={handleAction}
        btnGroups={btnGroups}
        disabledCheckbox={true}
      />
    </>
  );
}

export default View;
