import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import labels from "../../constants/NotificationLog";
import commonConstant from "../../constants/Common";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { pluck } from 'underscore';

function Add({ history }) {
  const [languages, setLanguages] = useState([])
  const [userOption, setUserOption] = useState([]);
  const fieldArr1 = [
    { id: 2, title: "Message ", name: "message", type: "TextArea" },
    {
      id: 3,
      title: "User Type",
      name: "user_type",
      type: "Dropdown",
      options: commonConstant.user_type,
    },
    {
      id: 4,
      title: "Users",
      name: "user_id",
      type: "MultiSelect",
      options: userOption,
    }
  ]
  const [fields, setFields] = useState({
    user_id: []
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    // getUsers();
  }, []);


  function getUsers(user_type) {
    let URI = (user_type === 'customer')?"user/list":"driver/list"
    configuration
      .getAPI({ url: URI, params: { type: "pushUser", user_type } })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.label = `${element.name}`;
              element.id = (user_type === 'customer')?element.customer_id:element.driver_id;
              return element;
            });
          }
          setUserOption(data.payload)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields["message"]) {
      flag = false;
      error["message"] = "Please enter message.";
    }
    if (fields["user_id"].length <= 0) {
      flag = false;
      error["user_id"] = "Please select user.";
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN', user_id: pluck(fields.user_id, 'id') }));
      console.log({ ...fields, lang_code: 'EN', user_id: pluck(fields.user_id, 'id') })
      configuration.postFormDataAPI({ url: 'notification/send', params: formData }).then((data) => {
        if (data.status === 200) {
          history.push('/notification-log')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  function handleChange(field, e) {
    if (field === 'user_type') {
      setFields({ ...fields, [field]: e.target.value, user_id: [] })
      if (e.target.value && e.target.value !== '') {
        getUsers(e.target.value);
      } else {
        setUserOption([])
      }
    } else if (field === 'user_id') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  return (
    <>
      <Breadcrumb module={labels.SendNotification} />
      <AddEditSection title="Send Notification" path="/notification-log" fields={fieldArr1} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
