import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/Form/Dropdown";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import DatePicker from "../../../components/Form/DatePicker";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { findWhere, each } from 'underscore';

let Id = "";
// List Settings
function Vehicle(history) {
  const [editFlag, setEditFlag] = useState(false);
  const [brandOpt, setBrandOpt] = useState([]);
  const [compulInsuranceDoc, setCompulInsuranceDoc] = useState(null);
  const [nonCompulInsuranceDoc, setNonCompulInsuranceDoc] = useState(null);
  const [certificateOfRegistryDoc, setCertificateOfRegistryDoc] = useState(null);
  const [fields, setFields] = useState({});
  const [vehicleList, setVehicleList] = useState([]);
  const [listDocs, setListDocs] = useState([]);
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];

  const fieldArr2 = [
    // { id: 1, title: "Name", name: "name", type: "TextInput" },
    { id: 2, title: "Brand", name: "brand_id", type: "Dropdown", options: brandOpt },
    { id: 3, title: "Model Code", name: "model_code", type: "TextInput" },
    { id: 4, title: "Color", name: "color", type: "TextInput" },
    { id: 5, title: "Register Date", name: "register_date", type: "Date", disableFuture:true },
    { id: 6, title: "License Plate", name: "license_plate", type: "TextInput" },
    { id: 13, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
    { id: 7, title: "Compulsory Insurance", name: "compul_insurance_doc", type: "FileInput" },
    { id: 8, title: "Compulsory Insurance Expiration Date", name: "compul_insurance_expiry_date", type: "Date", disablePast:true },
    { id: 9, title: "Non Compulsory Insurance", name: "non_compul_insurance_doc", type: "FileInput" },
    { id: 10, title: "Non Compulsory Insurance Expiration Date", name: "non_compul_insurance_expiry_date", type: "Date", disablePast:true },
    { id: 11, title: "Certificate of Registry", name: "certificate_of_registry_doc", type: "FileInput" },
    { id: 12, title: "Certificate Expiration Date", name: "certificate_expiry_date", type: "Date", disablePast:true },
  ];

  const [docs, setDocs] = useState([]);
  const [errors, setErrors] = useState({
    brand_id: "",
    model_code: "",
    color: "",
    register_date: "",
    license_plate: "",
    compul_insurance_doc: "",
    compul_insurance_expiry_date: "",
    certificate_of_registry_doc: "",
    certificate_expiry_date: ""
  });

  useEffect(() => {
    brandList();
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    setFields({ ...fields, user_id: Id })
    getVehicle(Id);
  }, []);

  function getVehicle(Id) {
    configuration.getAPI({ url: 'vehicle/list', params: { user_id: Id } }).then((data) => {
      if (data.status === 200) {
        setVehicleList(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function brandList() {
    configuration.getAPI({ url: 'brand/list' }).then((data) => {
      if (data.status === 200) {
        let titleArr = []
        data.payload.map((singleLang) => {
          titleArr.push({ id: singleLang.brand_id, title: `${singleLang.title['EN']}`, value: `${singleLang.brand_id}` })
        })
        setBrandOpt(titleArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

  }

  function handleChange(field, e) {
    if (field === 'compul_insurance_doc') {
      setCompulInsuranceDoc(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    }
    else if (field === 'non_compul_insurance_doc') {
      setNonCompulInsuranceDoc(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    }
    else if (field === 'certificate_of_registry_doc') {
      setCertificateOfRegistryDoc(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    }
    else if (field === 'register_date' || field === 'compul_insurance_expiry_date' || field === 'non_compul_insurance_expiry_date' || field === 'certificate_expiry_date') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  function cancleImage(field, e) {
    if (field === 'compul_insurance_doc') {
      setCompulInsuranceDoc(null);
    }
    else if (field === 'non_compul_insurance_doc') {
      setNonCompulInsuranceDoc(null);
    }
    else if (field === 'certificate_of_registry_doc') {
      setCertificateOfRegistryDoc(null);
    }
    setFields({ ...fields, [field]: '' })
  }

  function validation() {
    let flag = true;
    let error = {}

    if (!fields.brand_id) {
      error['brand_id'] = "Please select brand"
      flag = false
    }

    if (!fields.model_code) {
      error['model_code'] = "Please enter model code"
      flag = false
    }

    if (!fields.color) {
      error['color'] = "Please enter color"
      flag = false
    }

    if (!fields.register_date) {
      error['register_date'] = "Please select register date"
      flag = false
    }

    if (!fields.license_plate) {
      error['license_plate'] = "Please enter license plate"
      flag = false
    }

    if (!fields.compul_insurance_doc) {
      error['compul_insurance_doc'] = "Please upload compulsory insurance"
      flag = false
    }

    if (!fields.compul_insurance_expiry_date) {
      error['compul_insurance_expiry_date'] = "Please select insurance expiration date"
      flag = false
    }

    if (!fields.certificate_of_registry_doc) {
      error['certificate_of_registry_doc'] = "Please upload certificate of registry"
      flag = false
    }

    if (!fields.certificate_expiry_date) {
      error['certificate_expiry_date'] = "Please select certificate expiration date"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      const formData = new FormData();
      if (compulInsuranceDoc) {
        formData.append('compul_insurance_doc', compulInsuranceDoc);
      }
      if (nonCompulInsuranceDoc) {
        formData.append('non_compul_insurance_doc', nonCompulInsuranceDoc);
      }
      if (certificateOfRegistryDoc) {
        formData.append('certificate_of_registry_doc', certificateOfRegistryDoc);
      }
      formData.append('fields', JSON.stringify({ fields, lang_code: 'EN' }));

      if (editFlag) {
        configuration.postFormDataAPI({ url: 'vehicle/update', params: formData }).then((data) => {
          if (data.status === 200) {
            toast.success('Record updated successfully')
            getVehicle(Id);
            setFields({ name: "", brand_id: "", model_code: "", color: "", license_plate: "" });
          } else if (data.error) {
            return toast.error(data.error.message)
          } else {
            return toast.error('Something went wrong')
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      } else {
        configuration.postFormDataAPI({ url: 'vehicle/create', params: formData }).then((data) => {
          if (data.status === 200) {
            toast.success('Record added successfully')
            getVehicle(Id);
            setFields({ name: "", brand_id: "", model_code: "", color: "", license_plate: "" });
          } else if (data.error) {
            return toast.error(data.error.message)
          } else {
            return toast.error('Something went wrong')
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      }
    }
  }

  function handleDelete(vehicle_id) {
    const sendData = {
      ids: vehicle_id,
      type: 'delete'
    }
    configuration.postAPI({ url: "vehicle/action", params: sendData })
      .then((data) => {
        if (data.status === 200) {
          getVehicle(Id);
          return toast.error("Vehicle deleted successfully");
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleEdit(vehicle_id) {
    configuration.getAPI({ url: 'vehicle/list', params: { vehicle_id: vehicle_id } }).then((data) => {
      if (data.status === 200) {
        let fieldData = {}
        each(data.payload.vehicle_documents, single => {
          fieldData[single.vehicle_document_id] = single.photo;
        })
        setFields({ ...data.payload, ...fieldData })
        setErrors({})
        setEditFlag(true)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleClear() {
    setEditFlag(false)
    setFields({
      vehicle_id: '',
      plate_no: '',
    })
    setDocs([]);
    setErrors({})
    getVehicle(Id);
  }

  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Vehicle Info
            </h2>
            <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show customer vehicles details.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
              Add Vehicle
            </h2>
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {fieldArr2.map((field) => {
                return (
                  <div className="sm:col-span-3" key={field.id}>
                    <Label title={field.title} />
                    <div className="mt-1">
                      {field.type === "TextInput" ? (
                        <TextInput
                          id={field.name}
                          name={field.name}
                          value={fields[field.name]}
                          handleChange={handleChange}
                          is_number={field.is_number || false}
                          is_password={field.is_password || false}
                        />
                      ) : field.type === "Dropdown" ? (
                        <Dropdown
                          id={field.name}
                          value={fields[field.name]}
                          name={field.name}
                          handleChange={handleChange}
                          status={field.options || []}
                        />
                      ) : field.type === "FileInput" ? (
                        <FileInput
                          id={field.name}
                          value={fields[field.name]}
                          handleChange={handleChange}
                          cancleImage={cancleImage}
                          name={field.name}
                        />
                      ) : field.type === "Date" ? (
                        <DatePicker
                          id={field.name}
                          name={field.name}
                          disableFuture={field.disableFuture}
                          disablePast={field.disablePast}
                          handleChange={handleChange}
                          value={fields[field.name]}
                        />
                      ) :
                        null}
                    </div>
                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                  </div>
                );
              })}
            </div>
            <div className="pt-5 border_color_theme">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                  onClick={() => handleClear()}
                >
                  Clear
                </button>
                <BtnSaveRecord title={(editFlag) ? "Update" : "Save"} handleSubmit={handleSubmit} path="/customerprofile" />
              </div>
            </div>

            <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
              Vehicle
            </h2>

            <ul role="list" className="grid grid-cols-2 gap-6">
              {vehicleList.map((singleVehicle) => (
                <li key={singleVehicle.vehicle_id} className="flex py-6 sm:py-6 px-4 shadow-lg rounded-md">
                  {/*<div className="flex-shrink-0">
                                        <img
                                            src={singleVehicle.photo}
                                            alt={singleVehicle.vehicle_id}
                                            className="w-16 h-16 rounded-md object-center object-cover sm:w-20 sm:h-20"
                                        />
                                    </div>*/}

                  <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                      <div>
                        <div className="flex justify-between">
                          <h3 className="text-base">
                            <p className="font-semibold text-gray-700 hover:text-gray-800">
                              {singleVehicle.license_plate}
                            </p>
                          </h3>
                        </div>
                        <p className="mt-1 text-basefont-medium text-gray-900">{singleVehicle.brand_name}</p>
                        <div className="mt-1 flex text-sm">
                          <p className="text-gray-500">{singleVehicle.model_code}</p>
                        </div>
                      </div>

                      <div className="mt-4 sm:mt-0">
                        <div className="absolute top-0 right-12">
                          <button type="button" onClick={(e) => handleEdit(singleVehicle.vehicle_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                            <span className="sr-only">Edit</span>
                            <PencilIcon className="h-5 w-5 " aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-4 sm:mt-0">
                        <div className="absolute top-0 right-0">
                          <button type="button" onClick={(e) => handleDelete(singleVehicle.vehicle_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                            <span className="sr-only">Remove</span>
                            <TrashIcon className="h-5 w-5 " aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Vehicle;