import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/LoginLogs";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    { title: "ID", field: "loginlog_id"},
    // { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Login", field: "login_date" },
    { title: "Logout", field: "logout_date" },
    { title: "IP", field: "ip" },
  ];

  const btnGroups = [];
  const [data, setData] = useState([]);
  
  useEffect(() => {
    configuration.getAPI({url:'common/list-login-logs', params:{}}).then((data) => {
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.loginLogs} />
      <ListSection
        captionTitle="View Login Log"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
