import React, { useEffect, useState } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import TextInput from "../../components/Form/TextInput";
import SwitchInput from "../../components/Form/SwitchInput";
import labels from "../../constants/PromotionCode";
import configuration from '../../config';
import { toast } from 'react-toastify';
import DatePicker from "../../components/Form/DatePicker";
import MultiSelect from "../../components/Form/MultiSelect";
import { pluck } from 'underscore';


let Id = "";
function Edit({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];

  const oneTimeOpt = [
    { id: 1, title: "Yes", value: true },
    { id: 2, title: "No", value: false },
  ];

  const discountTypeOpt = [
    { id: 0, title: "Percentage", value: "percentage" },
    { id: 1, title: "Amount", value: "amount" },
  ];

  const useTypeOpt = [
    { id: 0, title: "All Users", value: "all_user" },
    { id: 1, title: "Selected Users", value: "selected_user" },
  ];
  const [userOption, setUserOption] = useState([]);
  const fieldArr = [
    { id: 1, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
    { id: 2, title: "Coupon Code", name: "coupon_code", type: "TextInput", placeholder: "Enter your coupon code", is_read_only:true },
    { id: 3, title: "Start Date", name: "start_date", type: "Date" },
    { id: 4, title: "End Date", name: "end_date", type: "Date" },
    { id: 5, title: "Total Number Of Usage", name: "total_number_of_usage", type: "TextInput", is_number: true, placeholder: "Enter your total number of usage" },
    { id: 6, title: "One Time Usage", name: "is_one_time_usage", type: "Dropdown", options: oneTimeOpt },
    { id: 7, title: "Discount Type", name: "discount_type", type: "Dropdown", options: discountTypeOpt },
    { id: 8, title: "Value", name: "value", type: "TextInput", is_number: true, placeholder: "Enter your value" },
    { id: 9, title: "Max. Discount Value", name: "maximum_discount_value", type: "TextInput", is_number: true },
    { id: 10, title: "Use Type", name: "use_type", type: "Dropdown", options: useTypeOpt },
    {
      id: 11,
      title: "Customer",
      name: "user_ids",
      type: "MultiSelect",
      options: userOption,
    }
    // { id: 9, title: "Min. Order Value", name: "min_order_value", type: "TextInput" },
  ];

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({ status: "" });
  const [fieldArr2, setFieldArr2] = useState([]);
  const [language, setLanguage] = useState([])

  useEffect(() => {
    getUsers();
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'promotion-code/list', params: { promotion_code_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        let titleArr = [];
        let descriptionArr = []
          ;
        data.payload.map((singleLang) => {
          titleArr.push({ id: 0, title: `Title [${singleLang.title}] `, name: `title_${singleLang.code}`, type: "TextInput", placeholder: `Enter your title in ${singleLang.title}` })
          descriptionArr.push({ id: singleLang.language_id, title: `Description [${singleLang.title}]`, name: `description_${singleLang.code}`, type: "TextInput", placeholder: `Enter your description in ${singleLang.title}` })
        })
        setLanguage(data.payload)
        const finalArr = (titleArr.concat(descriptionArr))
        setFieldArr2(finalArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleChange(field, e) {
    if (field.includes("title_")) {
      const lang = field.replace("title_", "")
      const title = {}
      fields.title[lang] = e.target.value
      setFields({ ...fields, [field]: e.target.value })
      return
    }
    if (field.includes("description_")) {
      const lang = field.replace("description_", "")
      const description = {}
      fields.description[lang] = e.target.value
      setFields({ ...fields, [field]: e.target.value })
      return
    }
    if (field === "start_date" || field === "end_date") {
      // fields.description[lang] = e.target.value
      setFields({ ...fields, [field]: e })
      return
    }
    if (field === 'user_ids') {
      console.log(field)
      console.log(e)
      setFields({ ...fields, [field]: e })
      return
    }
    setFields({ ...fields, [field]: e.target.value })
    return
  }



  function validation() {
    let flag = true;
    let error = {}
    language.forEach((single) => {
      if (!fields["title_" + single.code]) {
        error["title_" + single.code] = "Please enter your valid title in " + single.title
        flag = false
      }
      if (!fields["description_" + single.code]) {
        error["description_" + single.code] = "Please enter your valid description in " + single.title
        flag = false
      }
    })

    if (!fields.status) {
      error['status'] = "Please select any one status"
      flag = false
    }
    if (!fields.coupon_code) {
      error['coupon_code'] = "Please enter valid  coupon code"
      flag = false
    }
    if (!fields.start_date) {
      error['start_date'] = "Please select start date"
      flag = false
    }
    if (!fields.end_date) {
      error['end_date'] = "Please select end date"
      flag = false
    }
    if (fields['start_date'] > fields['end_date']) {
      flag = false;
      error["end_date"] = "Select End Time is Greater then Start Time.";
    }
    if (!fields.total_number_of_usage) {
      error['total_number_of_usage'] = "Please enter total number of usage"
      flag = false
    }

    if (!fields.is_one_time_usage) {
      error['is_one_time_usage'] = "Please enter one time password"
      flag = false
    }
    if (!fields.discount_type) {
      error['discount_type'] = "Please select discount type"
      flag = false
    }

    if (!fields.use_type) {
      error['use_type'] = "Please select use type"
      flag = false
    }

    if (fields.use_type === 'selected_user' && (!fields.user_ids || fields?.user_ids?.length <= 0)) {
      error['user_ids'] = "Please select at least one customer"
      flag = false
    } else {
      error['user_ids'] = ""
    }

    if (!fields.value) {
      error['value'] = "Please enter valid value"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  function getUsers(user_type) {
    configuration
      .getAPI({ url: "user/list", params: { status: 'active', user_type: "appUser" } })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.label = (element.name && element.name !== '') ? `${element.name}` : element.email;
              element.id = element.customer_id;
              return element;
            });
          }
          setUserOption(data.payload)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  const handleSubmit = () => {
    if (validation()) {
      // fields = {...fields, user_ids:pluck(fields.user_ids, 'id')}
      configuration.postAPI({ url: 'promotion-code/update', params: { ...fields, user_ids: pluck(fields.user_ids, 'id') } }).then((data) => {
        if (data.status === 200) {
          toast.success('Record updated successfully');
          return history.push('/promotion-code');
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.editpromotionCode} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Edit Promotion Code" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="">
                      <main className="relative">
                        <div className="mx-auto">
                          <div className="">
                            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-1 lg:divide-y-0">
                              <div className="py-2 col-span-10">
                                <section aria-labelledby="applicant-information-title">
                                  <div className="">
                                    <div className="px-4 py-5 sm:px-6">
                                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        {[...fieldArr2, ...fieldArr].map((field) => {
                                          return (
                                            <div className="sm:col-span-3" key={field.id}>
                                              <Label title={field.title} />
                                              <div className="mt-1">
                                                {field.type === "TextInput" ? (
                                                  <TextInput
                                                    id={field.name}
                                                    placeholder={field.placeholder}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    is_read_only={field.is_read_only || false}
                                                    is_number={field.is_number || false}
                                                    is_password={field.is_password || false}
                                                  />
                                                ) : field.type === "FileInput" ? (
                                                  <FileInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                  />
                                                ) : field.type === "Dropdown" ? (
                                                  <Dropdown
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    status={field.options || []}
                                                  />
                                                ) : field.type === "SwitchInput" ? (
                                                  <SwitchInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                  />
                                                ) : field.type === "TextArea" ? (
                                                  <TextArea
                                                    id={field.name}
                                                    name={field.name}
                                                  />
                                                ) : field.type === "MultiSelect" ? (
                                                  <MultiSelect id={field.name} name={field.name} value={fields[field.name]} options={field.options || []} isSelectAll={'no'} handleChange={handleChange} />
                                                ) : field.type === "Date" ? (
                                                  <DatePicker
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                  />
                                                ) : null}
                                              </div>
                                              {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="pt-5 border_color_theme">
                                        <div className="flex justify-end">
                                          <BtnRedirect title="Cancel" path="/promotion-code" />
                                          <BtnSaveRecord handleSubmit={handleSubmit} title="Submit" path="/promotion-code" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>


                            </div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit;
