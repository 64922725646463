import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Language";
import _ from 'underscore'
// For Edit Language
let Id = "";

function Edit({ history }) {
  const statusOpt = [

    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];

  const [fieldArr, setFieldArr] = useState([
    { id: 0, title: "Title", name: "title", type: "TextInput", placeholder: "Enter your title" },
    { id: 1, title: "Code", name: "code", type: "TextInput", is_read_only: true, placeholder: "Enter your code" },

    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    code: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter language title"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please enter language Code"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please Select any one status"
      flag = false
    }
    if (!fields.order_no) {
      error['order_no'] = "Please Select any one order number"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "language/list", params: { language_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    configuration.getAPI({
      url: 'common/list-order-no', params: {
        collectionName: 'languages', lang_code: "EN", is_update: 1
      }
    }).then((data) => {
      if (data.status === 200) {
        let orderNoArr = []
        _.each(data.payload, (singleRec) => {
          orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
        })
        const orderNoObj = {
          id: 1,
          title: "Order No",
          name: "order_no",
          type: "Dropdown",
          options: orderNoArr,
        }
        fieldArr.push(orderNoObj)
        setFieldArr([...fieldArr])
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "language/update", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/language");
            // setData(data.payload)
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editLanguage} />
      <AddEditSection
        title="Edit Language"
        path="/language"
        fields={fieldArr}
        fieldError={errors}

        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
