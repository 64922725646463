import PropTypes from "prop-types";
import React, {useState, useEffect } from "react";
import labels from "../../constants/Reset";
import common from "../../constants/Common";
import configuration from '../../config';
import { toast } from 'react-toastify';
// import {reactLocalStorage} from 'reactjs-localstorage';

// var jwt = require('jsonwebtoken');

function View({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.password) {
      error['password'] = "Please Enter password"
      flag = false
      toast.error("Please Enter password");
    }
    if (!fields.confirm_password) {
      error['confirm_password'] = "Please Enter confirm password"
      flag = false
      toast.error("Please Enter confirm password");
    }
    if (fields.password !== fields.confirm_password) {
      error['password'] = "Password and confirm password doesn't match"
      flag = false
      toast.error("Password and confirm password doesn't match");
    }
    // console.log(error)
    setErrors({...error })
    return flag;
  }
  useEffect(() => {
    let url = window.location.href;
    let resetCode = url.substring(url.lastIndexOf("/") + 1);
    setFields({ ...fields, code:resetCode})
  }, []);

  const handleSubmit = () =>{
    // console.log("fields");
    if (validation()) {
      console.log(fields);
      configuration.postAPI({url:'auth/reset-password', params:fields}).then((data) => {
        // console.log(data)
        if(data.status === 200){
          toast.success("Your password has been changed successfully")
          history.push("/")
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 page_bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={'../image/logo.svg'}
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 text_theme">
          {labels.resetPasswordToYourAccount}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 box_bg">
          <form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700 text-base text_theme"
              >
                {labels.password}
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e)=>setFields({...fields, password: e.target.value})}
                  value={fields.password}
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                />
              </div>
              {errors.password ? <label title='password' fieldError={errors.password} /> : null}
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block font-medium text-gray-700 text-base text_theme"
              >
                {labels.confirmPassword}
              </label>
              <div className="mt-1">
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  onChange={(e)=>setFields({...fields, confirm_password: e.target.value})}
                  value={fields.confirm_password}
                  autoComplete="current-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                />
              </div>
              {errors.confirm_password ? <label title='confirm_password' fieldError={errors.confirm_password} /> : null}
            </div>
            <div>
              <button
                type="button"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSubmit}
              >
                {labels.resetPassword}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  history: PropTypes.func,
  // "history.push": PropTypes.func,
};

View.defaultProps = {
  history: () => {
     // do nothing.
  },
  // "history.push": () => {
  //    // do nothing.
  // },
};

export default View;
